
// Vue
import { computed, defineComponent, ref } from "vue";
// API
import { CreateUrlResourceModel, UrlResourceClient, UrlResourceModel } from "@/api/LumediApi";
// Vuelidate
import { useVuelidate } from "@vuelidate/core";
import { maxLength, required } from "@vuelidate/validators";
// Utilities
import utils from "@/utils/utils";
// Components
import ConfirmationDialog from "@/components/ConfirmationDialog.vue";
import Skeleton from "primevue/skeleton";

export default defineComponent({
	components: {
		Skeleton,
		ConfirmationDialog,
	},

	setup() {
		//#region Load
		const isLoading = ref(false);
		const hasLoadingFailed = ref(false);
		const links = ref<UrlResourceModel[]>(new Array(5));
		const isFormDisabled = computed(() => isLoading.value || hasLoadingFailed.value);

		loadUrlResources();

		async function loadUrlResources() {
			if (isLoading.value) return;
			isLoading.value = true;
			hasLoadingFailed.value = false;

			try {
				const client = new UrlResourceClient();
				const studyId = utils.sessionStudyId();
				const result = await client.getUrlResources(studyId);

				links.value = result;
			} catch (ex) {
				console.error(ex);
				hasLoadingFailed.value = true;
			}

			isLoading.value = false;
		}
		//#endregion Load

		//#region Add/Edit
		const showEditDialog = ref(false);
		const isEditSaving = ref(false);
		const hasSaveFailed = ref(false);
		const editModel = ref<UrlResourceModel>();
		const itemInContext = ref<{ model: UrlResourceModel; index: number }>();

		const rules = {
			editModel: {
				url: { required, maxLength: maxLength(2048) },
				description: { maxLength: maxLength(500) },
			},
		};

		const v$ = useVuelidate(rules, { editModel }, { $autoDirty: true });

		enum EditMode {
			New = "New",
			Edit = "Edit",
		}
		const editMode = ref<EditMode>();
		const editDialogHeader = computed(() => editMode.value && EditMode[editMode.value]);
		const saveClicked = ref(false);

		function closeEditDialog() {
			showEditDialog.value = false;
			editMode.value = undefined;
			editModel.value = undefined;
			saveClicked.value = false;
			hasSaveFailed.value = false;
		}

		async function onEditSave() {
			saveClicked.value = true;

			if (isEditSaving.value) return;

			const modelValid = await v$.value.$validate();
			if (!modelValid || !editModel.value?.url) return;

			isEditSaving.value = true;
			hasSaveFailed.value = false;

			try {
				const client = new UrlResourceClient();
				const studyId = utils.sessionStudyId();

				const newModel = new CreateUrlResourceModel({
					url: editModel.value.url,
					description: editModel.value.description,
				});

				if (editMode.value === EditMode.New) {
					const newId = await client.createUrlResource(studyId, newModel);
					console.log(newId);

					links.value.push(new UrlResourceModel({ urlResourceId: newId, ...newModel }));
				} else if (editMode.value === EditMode.Edit && itemInContext.value) {
					const urlResourceId = itemInContext.value.model.urlResourceId;
					await client.updateUrlResource(studyId, urlResourceId, newModel);

					links.value[itemInContext.value.index] = editModel.value;
				}

				closeEditDialog();
			} catch (ex) {
				console.error(ex);
				hasSaveFailed.value = true;
			}

			isEditSaving.value = false;
		}

		function openEditDialog() {
			showEditDialog.value = true;
			v$.value.$reset();
		}
		//#endregion Add/Edit

		//#region Edit
		function onEditClick(model: UrlResourceModel, index: number) {
			editMode.value = EditMode.Edit;
			itemInContext.value = { model, index };
			editModel.value = new UrlResourceModel(model);
			openEditDialog();
		}
		//#endregion Edit

		//#region Add
		function onAddClick() {
			editMode.value = EditMode.New;
			editModel.value = new UrlResourceModel();
			openEditDialog();
		}

		//#endregion Add

		//#region Delete
		const showDeleteDialog = ref(false);

		function onDeleteClick(model: UrlResourceModel, index: number) {
			itemInContext.value = { model, index };
			showDeleteDialog.value = true;
		}

		function closeDeleteDialog() {
			itemInContext.value = undefined;
			showDeleteDialog.value = false;
		}

		const isDeleteLoading = ref(false);
		const hasDeleteFailed = ref(false);

		async function onDeleteConfirm() {
			if (!itemInContext.value || isDeleteLoading.value) return;

			isDeleteLoading.value = true;
			hasDeleteFailed.value = false;

			try {
				const client = new UrlResourceClient();
				const studyId = utils.sessionStudyId();
				await client.deleteUrlResource(studyId, itemInContext.value.model.urlResourceId);

				links.value.splice(itemInContext.value.index, 1);
				closeDeleteDialog();
			} catch (ex) {
				console.error(ex);
				hasDeleteFailed.value = true;
			}

			isDeleteLoading.value = false;
		}
		//#endregion Delete

		return {
			// Load
			hasLoadingFailed,
			isFormDisabled,
			isLoading,
			links,
			loadUrlResources,

			// Add/Edit
			editDialogHeader,
			editModel,
			hasSaveFailed,
			isEditSaving,
			itemInContext,
			saveClicked,
			showEditDialog,
			v$,
			onAddClick,
			onCloseEditDialog: closeEditDialog,
			onEditClick,
			onEditSave,

			// Delete
			hasDeleteFailed,
			isDeleteLoading,
			showDeleteDialog,
			onCloseDeleteDialog: closeDeleteDialog,
			onDeleteClick,
			onDeleteConfirm,
		};
	},
});
