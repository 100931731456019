
import { PatientManagementClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import { computed, defineComponent, onMounted, ref } from "vue";
export default defineComponent({
	name: "delete-patient",
	components: {},
	setup() {
		const studyId = ref(utils.sessionStudyId());
		const hasDeletePermission = ref(false);

		const queryParams = new URLSearchParams(window.location.search);
		const patientId = ref(queryParams.get("id"));

		const deleteClient = new PatientManagementClient();

		const isLoading = ref(false);
		const loadingError = ref(false);

		const patientUniqueId = ref("");
		const displayConfirmDialog = ref(false);

		onMounted(async () => {
			try {
				await deleteClient.hasDeletePermission(studyId.value);
				hasDeletePermission.value = true;
			} catch (error) {
				hasDeletePermission.value = false;
			}
		});

		/**
		 * Display the delete confirmation modal.
		 */
		const displayDeleteConfirmation = async () => {
			if (patientId.value == null) return;

			displayConfirmDialog.value = true;
			isLoading.value = true;

			patientUniqueId.value = await deleteClient.getUniqueIdForDeleteValidation(patientId.value);

			isLoading.value = false;
		};

		const confirmUniqueId = ref("");
		const isDeleteLoading = ref(false);

		/**
		 * Confirm the deletion of a patient.
		 */
		const confirmDeletePatient = async () => {
			if (patientId.value == null) return;
			if (isLoading.value) return;
			if (!doesUniqueIdsMatch.value) return;

			loadingError.value = false;
			isDeleteLoading.value = true;

			try {
				await deleteClient.deletePatient(patientId.value, confirmUniqueId.value);

				location.reload();
			} catch (error) {
				loadingError.value = true;
			}

			isDeleteLoading.value = false;
		};

		/**
		 * If the loaded unique id of the patient matches the one entered in the input field.
		 */
		const doesUniqueIdsMatch = computed(() => confirmUniqueId.value === patientUniqueId.value);

		return {
			patientId,
			studyId,
			isLoading,
			loadingError,
			patientUniqueId,
			displayConfirmDialog,
			displayDeleteConfirmation,
			confirmDeletePatient,
			doesUniqueIdsMatch,
			confirmUniqueId,
			isDeleteLoading,
			hasDeletePermission,
		};
	},
});
