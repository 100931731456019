
// Vue
import { defineComponent } from "vue";
// API
import { MetricsClient } from "@/api/LumediApi";
// Utilities
import utils from "@/utils/utils";
// Components
import Skeleton from "primevue/skeleton";

export default defineComponent({
	components: {
		Skeleton,
	},

	data() {
		return {
			studyId: undefined as string | undefined,

			averageWaitTime: undefined as string | undefined,
			availableAgents: undefined as number | undefined,
		};
	},

	computed: {
		isLoaded(): boolean {
			return this.averageWaitTime !== undefined && this.availableAgents !== undefined;
		},
	},

	async mounted() {
		this.studyId = utils.sessionStudyId();

		const client = new MetricsClient();
		const response = await client.getQueueMetrics(this.studyId);

		this.averageWaitTime = response.averageWaitTime;
		this.availableAgents = response.totalAvailableAgents;
	},
});
