
import { PatientSummaryResult, ReportsClient, StoppedTakingSummary } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Skeleton from "primevue/skeleton";
import { defineComponent } from "vue";

export default defineComponent({
	components: {
		Skeleton,
	},
	data() {
		return {
			summary: null as StoppedTakingSummary | null,
			displayDetails: false,
			patients: [] as PatientSummaryResult[],
			studyId: "",
		};
	},
	computed: {
		totalStoppedTakingCount(): number {
			if (!this.summary) return 0;

			return this.summary.week2StoppedTakingCount + this.summary.week3StoppedTakingCount;
		},
	},
	async mounted() {
		this.studyId = utils.sessionStudyId();

		const reportsClient = new ReportsClient();

		this.summary = await reportsClient.getStoppedTakingSummary(this.studyId);
	},

	methods: {
		async loadDetails() {
			const reportsClient = new ReportsClient();

			this.patients = await reportsClient.getStoppedTakingPatients(this.studyId);
		},

		async displayPatients() {
			this.displayDetails = true;
			await this.loadDetails();
		},
	},
});
