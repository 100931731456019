
import { StudyParticipantsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import { defineComponent } from "vue";

const studyParticipantsClient = new StudyParticipantsClient();

export default defineComponent({
	data() {
		return {
			dailyEnrollments: 0,
			totalEnrollments: 0,
			studyId: null as string | null,
		};
	},

	async mounted() {
		this.studyId = utils.sessionStudyId();

		if (this.studyId == null) {
			console.log("No study id provided");
			return;
		}

		const overallResult = await studyParticipantsClient.getEnrollmentCount(this.studyId, null, null);

		this.totalEnrollments = overallResult.enrollments;

		const start = this.$dayjs().startOf("date").toDate();
		const end = this.$dayjs().endOf("date").toDate();

		const todayResult = await studyParticipantsClient.getEnrollmentCount(this.studyId, start, end);

		this.dailyEnrollments = todayResult.enrollments;
	},
});
