
import { PatientChartClient, PatientChartLinkResult } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Card from "primevue/card/Card";
import Message from "primevue/message";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	name: "patient-program-links",
	components: { Card, Message },
	setup() {
		const studyId = ref(utils.sessionStudyId());
		const participantId = ref<string | null>();
		const links = ref([] as PatientChartLinkResult[]);

		onMounted(async () => {
			const client = new PatientChartClient();

			const queryParams = new URLSearchParams(window.location.search);

			participantId.value = queryParams.get("id");

			if (!participantId.value) return;

			links.value = await client.getChartLinks(studyId.value, participantId.value);
		});

		return {
			studyId,
			participantId,
			links,
		};
	},
});
