
import { StudyParticipantsClient, type ServiceStatusSummaryResult } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Skeleton from "primevue/skeleton";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	name: "saxenda-service-statuses",
	components: {
		Skeleton,
	},
	setup(props, context) {
		const studyId = ref(utils.sessionStudyId());
		const summary = ref(undefined as ServiceStatusSummaryResult | undefined);

		onMounted(async () => {
			const studyParticipantsClient = new StudyParticipantsClient();
			summary.value = await studyParticipantsClient.getServiceSummary(studyId.value);
		});

		const isLoading = computed(() => summary.value === undefined);

		const totalQuick = computed(() => {
			if (summary.value === undefined) return 0;

			return summary.value.quickSupport;
		});

		const totalFull = computed(() => {
			if (summary.value === undefined) return 0;

			return summary.value.fullSupport;
		});

		const totalDiscount = computed(() => {
			if (summary.value === undefined) return 0;

			return summary.value.discount;
		});

		const totalReimbursement = computed(() => {
			if (summary.value === undefined) return 0;

			return summary.value.reimbursement;
		});

		return {
			studyId,
			summary,
			isLoading,

			totalQuick,
			totalFull,
			totalDiscount,
			totalReimbursement,
		};
	},
});
