
import { QuestionModel } from "@/api/LumediApi";
import QuestionRenderer from "@/components/ae-engine/QuestionRenderer.vue";
import Card from "primevue/card";
import { defineComponent } from "vue";

/**
 * Renders questions in a linear (list) layout.
 */
export default defineComponent({
	props: {
		questions: {
			type: Array as () => QuestionModel[],
			default: () => [],
		},
		form: {
			type: Object as () => { [k: string]: { [k: string]: any | undefined } },
			required: true,
		},
	},
	components: {
		QuestionRenderer,
		Card,
	},

	setup(props, context) {
		const updateAnswer = (questionId: number, value: { [k: string]: any | undefined }) => {
			const event = {
				questionId: questionId,
				answer: value,
			};

			context.emit("input", event);
		};

		return {
			updateAnswer,
		};
	},
});
