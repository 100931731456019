import { render, staticRenderFns } from "./HeightQuestion.vue?vue&type=template&id=4b6c46f2"
import script from "./HeightQuestion.vue?vue&type=script&lang=ts"
export * from "./HeightQuestion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports