import DateQuestion from "@/components/ae-engine/question-types/DateQuestion.vue";
import DropDownQuestion from "@/components/ae-engine/question-types/DropDownQuestion.vue";
import EmailQuestion from "@/components/ae-engine/question-types/EmailQuestion.vue";
import HeightQuestion from "@/components/ae-engine/question-types/HeightQuestion.vue";
import InstructionQuestion from "@/components/ae-engine/question-types/InstructionQuestion.vue";
import MultiLineTextQuestion from "@/components/ae-engine/question-types/MultiLineTextQuestion.vue";
import MultipleChoiceQuestion from "@/components/ae-engine/question-types/MultipleChoiceQuestion.vue";
import NumberQuestion from "@/components/ae-engine/question-types/NumberQuestion.vue";
import SingleChoiceQuestion from "@/components/ae-engine/question-types/SingleChoiceQuestion.vue";
import TextQuestion from "@/components/ae-engine/question-types/TextQuestion.vue";
import WeightQuestion from "@/components/ae-engine/question-types/WeightQuestion.vue";

export interface QuestionTypeMetaData {
	questionComponent?: object;
}

export interface QuestionRendererLookup {
	[questionType: number]: QuestionTypeMetaData;
}

export const questionTypeLookup: QuestionRendererLookup = {
	// Instruction
	0: {
		questionComponent: InstructionQuestion,
	},

	// Identifier
	1: {},

	// Text
	2: {
		questionComponent: TextQuestion,
	},

	// Number
	3: {
		questionComponent: NumberQuestion,
	},

	// Date
	4: {
		questionComponent: DateQuestion,
	},

	// Time
	5: {},

	// Single Choice
	6: {
		questionComponent: SingleChoiceQuestion,
	},

	// Multiple Choice
	7: {
		questionComponent: MultipleChoiceQuestion,
	},

	// Matrix
	8: {},

	// MultiLineText
	9: {
		questionComponent: MultiLineTextQuestion,
	},

	// Email
	22: {
		questionComponent: EmailQuestion,
	},

	// Height
	25: {
		questionComponent: HeightQuestion,
	},

	// Weight
	26: {
		questionComponent: WeightQuestion,
	},

	// Dropdown
	35: {
		questionComponent: DropDownQuestion,
	},
};
