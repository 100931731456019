import Vue from "vue";

import "./piniaSetup";

// PrimeVue
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/bootstrap4-light-blue/theme.css";
Vue.use(PrimeVue, { ripple: true });

// Lumedi Assets
import "@/assets/lumedi.scss";
import "@/assets/utilities.scss";

import { DayJsPlugin } from "@/plugins/dayjs/dayjsPlugin";
Vue.use(DayJsPlugin);

// Importing and registering PrimeVue UI components that will be used commonly throughout all pages.
import Toast from "primevue/toast";
Vue.component("Toast", Toast);

import Button from "primevue/button";
Vue.component("Button", Button);

import Checkbox from "primevue/checkbox";
Vue.component("Checkbox", Checkbox);

import Chips from "primevue/chips";
Vue.component("Chips", Chips);

import Chip from "primevue/chip";
Vue.component("Chip", Chip);

import Dropdown from "primevue/dropdown";
Vue.component("Dropdown", Dropdown);

import DataTable from "primevue/datatable";
Vue.component("DataTable", DataTable);

import DataView from "primevue/dataview";
Vue.component("DataView", DataView);

import Column from "primevue/column";
Vue.component("Column", Column);

import ColumnGroup from "primevue/columngroup";
Vue.component("ColumnGroup", ColumnGroup);

import Row from "primevue/row";
Vue.component("Row", Row);

import Message from "primevue/message";
Vue.component("Message", Message);

import Dialog from "primevue/dialog";
Vue.component("Dialog", Dialog);

import ConfirmPopup from "primevue/confirmpopup";
Vue.component("ConfirmPopup", ConfirmPopup);

import Card from "primevue/card";
Vue.component("Card", Card);

import Calendar from "primevue/calendar";
Vue.component("Calendar", Calendar);

import OverlayPanel from "primevue/overlaypanel";
Vue.component("OverlayPanel", OverlayPanel);

import InputNumber from "primevue/inputnumber";
Vue.component("InputNumber", InputNumber);

import Panel from "primevue/panel";
Vue.component("Panel", Panel);

import InputText from "primevue/inputtext";
Vue.component("InputText", InputText);

import Textarea from "primevue/textarea";
Vue.component("Textarea", Textarea);

import InlineMessage from "primevue/inlinemessage";
Vue.component("InlineMessage", InlineMessage);

import ProgressSpinner from "primevue/progressspinner";
Vue.component("ProgressSpinner", ProgressSpinner);

import InputSwitch from "primevue/inputswitch";
Vue.component("InputSwitch", InputSwitch);

import ConfirmationService from "primevue/confirmationservice";
Vue.use(ConfirmationService);

import ToastService from "primevue/toastservice";
Vue.use(ToastService);

Vue.config.productionTip = false;

// ! Newly created Vue Components must be registered here.
// Importing top level components that will be the initially rendered component within pages.
// Once routing is introduced, this will not be needed.
import SourceDataValidationReport from "@/components/SourceDataValidationReport.vue";
Vue.component("source-data-validation-report", SourceDataValidationReport);

import ReconciliationReportCard from "@/components/ReconciliationReportCard.vue";
Vue.component("reconciliation-report-card", ReconciliationReportCard);

import PspCallMetrics from "@/components/psp/PspCallMetrics.vue";
Vue.component("psp-call-metrics", PspCallMetrics);

import PhysicianReports from "@/components/PhysicianReports.vue";
Vue.component("physician-reports", PhysicianReports);

import AdverseEventsCCGLOWReport from "@/components/AdverseEventsCCGLOWReport.vue";
Vue.component("adverse-events-ccglow-report", AdverseEventsCCGLOWReport);

import AdverseEventsStatistics from "@/components/AdverseEventsStatistics.vue";
Vue.component("adverse-events-statistics", AdverseEventsStatistics);

import MonthlyReports from "@/components/MonthlyReports.vue";
Vue.component("monthly-reports", MonthlyReports);

import WeeklyReport from "@/components/WeeklyReport.vue";
Vue.component("weekly-report", WeeklyReport);

import DailyEnrollmentsCard from "@/components/DailyEnrollmentsCard.vue";
Vue.component("daily-enrollments-card", DailyEnrollmentsCard);

import EmaSettings from "@/components/EmaSettings.vue";
Vue.component("ema-settings", EmaSettings);

import PatientAttachments from "@/components/PatientAttachments.vue";
Vue.component("patient-atttachments", PatientAttachments);

import ParticipantsSummary from "@/components/participants/ParticipantsSummary.vue";
Vue.component("participants-summary", ParticipantsSummary);

import UpdateStudySystemPatient from "@/components/psp/UpdateStudySystemPatient.vue";
Vue.component("update-study-system-patient", UpdateStudySystemPatient);

import UpdateStudyBrandingColor from "@/components/psp/UpdateStudyBrandingColor.vue";
Vue.component("update-study-branding", UpdateStudyBrandingColor);

import StudyBrandBar from "@/components/psp/StudyBrandBar.vue";
Vue.component("study-brand-bar", StudyBrandBar);

import StudyLanguageSettings from "@/components/studies/StudyLanguageSettings.vue";
Vue.component("study-language-settings", StudyLanguageSettings);

import StoppedTakingSaxendaCard from "@/components/StoppedTakingSaxendaCard.vue";
Vue.component("stopped-taking-saxenda-card", StoppedTakingSaxendaCard);

import QuestionRenderer from "@/components/ae-engine/QuestionRenderer.vue";
Vue.component("question-renderer", QuestionRenderer);

import QuestionItemRenderer from "@/components/ae-engine/QuestionItemRenderer.vue";
Vue.component("question-item-renderer", QuestionItemRenderer);

import AeInteraction from "@/components/ae/AeInteraction.vue";
Vue.component("ae-interaction", AeInteraction);

import WaitTimeAvailableAgents from "@/components/psp/WaitTimeAvailableAgents.vue";
Vue.component("wait-time-available-agents", WaitTimeAvailableAgents);

import AgentAudits from "@/components/tiles/AgentAudits.vue";
Vue.component("agent-audits", AgentAudits);

import InteractionConcurrentUsers from "@/components/ae-engine/InteractionConcurrentUsers.vue";
Vue.component("interaction-concurrent-users", InteractionConcurrentUsers);

import ManageFaxNumbers from "@/components/fax/ManageFaxNumbers.vue";
Vue.component("manage-fax-numbers", ManageFaxNumbers);

import ManageEmailSender from "@/components/email/ManageEmailSender.vue";
Vue.component("manage-email-sender", ManageEmailSender);

import Tooltip from "primevue/tooltip";
Vue.directive("tooltip", Tooltip);

import ServicesBanner from "@/components/psp/services-banner/ServicesBanner.vue";
Vue.component("services-banner", ServicesBanner);

import ConsultationServicesBanner from "@/components/psp/services-banner/ConsultationServicesBanner.vue";
Vue.component("consultation-services-banner", ConsultationServicesBanner);

import PatientProgramJourney from "@/components/psp/PatientProgramJourney.vue";
Vue.component("patient-program-journey", PatientProgramJourney);

import PatientWeightOverview from "@/components/psp/patient-weight/PatientWeightOverview.vue";
Vue.component("patient-weight-overview", PatientWeightOverview);

import PatientsWithoutPhysicianReport from "@/components/tiles/PatientsWithoutPhysicianReport.vue";
Vue.component("patients-without-physician-report", PatientsWithoutPhysicianReport);

import PatientInfoCard from "@/components/participants/PatientInfoCard.vue";
Vue.component("patient-info-card", PatientInfoCard);

import ServiceStatusCard from "@/components/tiles/ServiceStatusCard.vue";
Vue.component("service-statuses", ServiceStatusCard);

import ConsultationServiceStatusCard from "@/components/tiles/ConsultationServiceStatusCard.vue";
Vue.component("consultation-service-statuses", ConsultationServiceStatusCard);

import { BaseInteraction, CreateInteraction, EditInteraction } from "questionnaire-client";
Vue.component("base-interaction", BaseInteraction);
Vue.component("edit-interaction", EditInteraction);
Vue.component("create-interaction", CreateInteraction);

import ExportInteractionJson from "@/components/interactions/ExportInteractionJson.vue";
Vue.component("interaction-export-json", ExportInteractionJson);

import ImportInteractionJson from "@/components/interactions/ImportInteractionJson.vue";
Vue.component("interaction-import-json", ImportInteractionJson);

import PatientProgramLinks from "@/components/psp/PatientProgramLinks.vue";
Vue.component("patient-program-links", PatientProgramLinks);

import SupportResourcesLinks from "@/components/support-resources/SupportLinks.vue";
Vue.component("support-resources-links", SupportResourcesLinks);

import DeletePatient from "@/components/participants/DeletePatient.vue";
Vue.component("delete-patient", DeletePatient);

import ParticipantsTable from "@/components/participants/search/ParticipantsTable.vue";
Vue.component("participants-table", ParticipantsTable);

import InteractionAnswerGroups from "@/components/participants/InteractionAnswerGroups.vue";
Vue.component("interaction-answer-groups", InteractionAnswerGroups);

// We do not have a traditional SPA yet.
// Each page controls its instantiation of the Vue instance.

(window as any).Vue = Vue;
