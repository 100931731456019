
// TODO: Label for id in template

import { QuestionOption } from "@/api/LumediApi";
import Calendar from "primevue/calendar";
import RadioButton from "primevue/radiobutton";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: String,
			required: false,
		},
		options: {
			type: Array as () => QuestionOption[],
		},
		disabled: {
			type: Boolean,
			default: false,
			required: false,
		},
		required: {
			type: Boolean,
			default: true,
			required: false,
		},
	},
	components: {
		RadioButton,
		Calendar,
	},
	computed: {
		localValue: {
			get(): string | undefined {
				return this.value;
			},
			set(value: string | undefined) {
				this.$emit("input", value);
			},
		},
	},
	methods: {
		clear() {
			this.localValue = undefined;
		},
	},
});
