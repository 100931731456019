
import { defineComponent } from "vue";
import ServicesBannerIndicator from "./ServicesBannerIndicator.vue";

export default defineComponent({
	name: "ServicesBannerConsultationType",
	components: { ServicesBannerIndicator },
	props: {
		oneTimeConsult: { type: Boolean, required: true, default: false },
		ongoingSupport: { type: Boolean, required: true, default: false },
	},
});
