
// Vue
import { type IPatientWithInteractionSummaryResult, ReportsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import { defineComponent } from "vue";
import PatientInteractionTable from "./PatientInteractionTable.vue";

export default defineComponent({
	name: "adverse-events-ccglow-report",

	components: { PatientInteractionTable },

	data: () => ({
		aeWithoutCcglowCount: 0,
		aeContainingCcglowCount: 0,
		showWithCcglowModal: false,
		showWithoutCcglowModal: false,
		patientsWithCcglow: [] as IPatientWithInteractionSummaryResult[],
		patientsWithCcglowLoading: false,
		patientsWithoutCcglow: [] as IPatientWithInteractionSummaryResult[],
		patientsWithoutCcglowLoading: false,
		studyId: "",
	}),

	async mounted() {
		this.studyId = utils.sessionStudyId();

		const reportClient = new ReportsClient();
		const summary = await reportClient.getCcglowSummary(this.studyId);

		this.aeWithoutCcglowCount = summary.aeWithoutCcglowCount;
		this.aeContainingCcglowCount = summary.aeContainingCcglowCount;
	},

	methods: {
		async displayWithCcglowDialog() {
			this.showWithCcglowModal = true;

			const reportsClient = new ReportsClient();
			try {
				this.patientsWithCcglowLoading = true;
				this.patientsWithCcglow = await reportsClient.getPatientsWithAeContainingCcglow(
					this.studyId,
					true,
				);
			} finally {
				this.patientsWithCcglowLoading = false;
			}
		},

		async displayWithoutCcglowDialog() {
			this.showWithoutCcglowModal = true;

			if (this.studyId == null) {
				console.log("No study Id provided");
				return;
			}

			const reportsClient = new ReportsClient();
			try {
				this.patientsWithoutCcglowLoading = true;
				this.patientsWithoutCcglow = await reportsClient.getPatientsWithAeContainingCcglow(
					this.studyId,
					false,
				);
			} finally {
				this.patientsWithoutCcglowLoading = false;
			}
		},
	},
});
