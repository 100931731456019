
// Vue
import { defineComponent } from "vue";
// Axios
import utils from "@/utils/utils";
import axios from "axios";

export default defineComponent({
	name: "physician-reports",

	data: () => ({
		studyId: null as string | null,
	}),

	mounted() {
		this.studyId = utils.sessionStudyId();

		if (this.studyId == null) {
			console.log("No study id provided");
			return;
		}
	},

	methods: {
		/**
		 * Downloads a Physician Report file for data between this.startDate and this.endDate.
		 * @author Nick Brahimir
		 */
		async downloadPhysicianReports() {
			// TODO - update this URL for Physicians.

			// this.studyId = utils.selectedStudyId();

			if (this.studyId == null) {
				console.log("No study id provided");
				return;
			}

			const url = `/api/v1/lumedi-connect-reports/studies/${this.studyId}/physicians`;
			await axios({
				url: url,
				method: "GET",
				responseType: "blob",
			})
				.then((response: any) => {
					const fileURL = window.URL.createObjectURL(new Blob([response.data]));
					const fileLink = document.createElement("a");
					fileLink.href = fileURL;
					fileLink.setAttribute("download", "Physician Report.xlsx");
					document.body.appendChild(fileLink);
					fileLink.click();
				})
				.catch((error: any) => {
					this.$toast.add({
						severity: "error",
						summary: "Could not download Physician Report",
						life: 3000,
					});
					console.log(error);
				});
		},
	},
});
