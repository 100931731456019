
import { NewInteractionQuestionItem, QuestionType } from "@/api/LumediApi";
import { questionTypeLookup } from "@/components/ae-engine/QuestionTypeLookup";
import NumberQuestion from "@/components/ae-engine/question-types/NumberQuestion.vue";
import { defineComponent } from "vue";

/**
 * Renders an item in a question.
 */
export default defineComponent({
	props: {
		value: {
			type: [String, Number, Object, Date],
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		model: {
			type: NewInteractionQuestionItem,
			required: true,
		},
		displayLabels: {
			type: Boolean,
			default: true,
		},
		displayInstructions: {
			type: Boolean,
			default: true,
		},
		textAreaRows: {
			type: Number,
			default: 5,
		},
		textAreaCols: {
			type: Number,
			default: 100,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		NumberQuestion,
	},
	computed: {
		/**
		 * The type of question to render.
		 */
		questionType(): QuestionType {
			return this.model.questionType;
		},

		/**
		 * If there is a question component implementation for the question type.
		 */
		hasComponentType(): boolean {
			return this.questionComponentType !== undefined;
		},

		/**
		 * The type of component that will render the question.
		 */
		questionComponentType(): object | undefined {
			const typeLookup = questionTypeLookup[this.questionType];

			if (typeLookup === undefined) {
				return undefined;
			}

			return typeLookup.questionComponent;
		},

		/**
		 * The local value of the value prop.
		 * To keep a one-way data flow, when a change is made propagate the events upstream rather than directly modifying the prop.
		 */
		localValue: {
			get(): any {
				return this.value;
			},
			set(value: any) {
				this.$emit("input", value);
			},
		},
	},
});
