
import { PatientWeightInfoResult } from "@/api/LumediApi";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	name: "PatientWeightInfo",
	props: {
		model: {
			type: Object as PropType<PatientWeightInfoResult>,
			default: () => ({}),
		},
	},
	setup(props) {
		const localModel = computed(() => ({
			heightInCm: props.model.heightInCm?.toFixed(1),
			initialBmi: props.model.initialBmi?.toFixed(1),
			currentBmi: props.model.currentBmi?.toFixed(1),
			weightLost: props.model.weightLost?.toFixed(1),
			weightLostPercentage: props.model.weightLostPercentage?.toFixed(1),
		}));
		return {
			localModel,
		};
	},
});
