
// TODO: Label for id in template

import Textarea from "primevue/textarea";
import { defineComponent } from "vue";
export default defineComponent({
	components: {
		Textarea,
	},
	props: {
		value: {
			type: String,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		displayLabels: {
			type: Boolean,
			default: true,
		},
		textAreaRows: {
			type: Number,
			default: 5,
		},
		textAreaCols: {
			type: Number,
			default: 100,
		},
	},
	computed: {
		localValue: {
			get(): string | undefined {
				return this.value;
			},
			set(value: string | undefined) {
				this.$emit("input", value);
			},
		},
	},
});
