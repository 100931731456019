
// TODO: Label for id in template

import InputText from "primevue/inputtext";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: String,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		displayLabels: {
			type: Boolean,
			default: true,
		},
	},
	components: {
		InputText,
	},
	computed: {
		localValue: {
			get(): string | undefined {
				return this.value;
			},
			set(value: string | undefined) {
				this.$emit("input", value);
			},
		},
	},
});
