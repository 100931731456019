import { PatientInteractionAction, usePatientInteractionStore } from "@/store/patientInteractionStore";
import * as signalR from "@microsoft/signalr";
import _Vue from "vue";

const connection = new signalR.HubConnectionBuilder()
	.configureLogging(signalR.LogLevel.Information)
	.withUrl(`/patientInteractionHub`, {
		accessTokenFactory: () => {
			const accessToken = sessionStorage.getItem("access_token");

			return accessToken ?? "";
		},
	})
	.withAutomaticReconnect({
		nextRetryDelayInMilliseconds: (retryContext) => {
			const reconnectDelay = Math.random() * 5000 + 2000;
			return reconnectDelay;
		},
	})
	.build();

connection.onreconnecting(function () {});

connection.onreconnected(function () {
	const patientInteractionStore = usePatientInteractionStore();
	patientInteractionStore.isHubConnected = true;

	// SignalR groups are lost on disconnect.
	if (patientInteractionStore.interactionId !== null && patientInteractionStore.patientId !== null) {
		patientInteractionHub.connectToNewPatientInteraction(
			patientInteractionStore.patientId,
			patientInteractionStore.interactionId,
		);
	} else if (patientInteractionStore.patientInteractionId !== null) {
		patientInteractionHub.connectToEditPatientInteraction(patientInteractionStore.patientInteractionId);
	}
});

connection.on("NewStateUpdate", async (patientId: string, interactionId: number, count: number) => {
	const patientInteractionStore = usePatientInteractionStore();

	if (
		patientInteractionStore.patientId === patientId &&
		patientInteractionStore.interactionId === interactionId
	) {
		patientInteractionStore.concurrentUserCount = count;
		patientInteractionStore.patientInteractionAction = PatientInteractionAction.Create;
	}
});

connection.on("EditStateUpdate", async (patientInteractionId: number, count: number) => {
	const patientInteractionStore = usePatientInteractionStore();

	if (patientInteractionStore.patientInteractionId === patientInteractionId) {
		patientInteractionStore.concurrentUserCount = count;
		patientInteractionStore.patientInteractionAction = PatientInteractionAction.Edit;
	}
});

export interface PatientInteractionHub {
	connection: signalR.HubConnection;
	start(): Promise<void>;
	stop(): Promise<void>;
	connectToNewPatientInteraction(patientId: string, interactionId: number): Promise<void>;
	connectToEditPatientInteraction(patientInteractionId: number): Promise<void>;
}

export const patientInteractionHub = {
	connection: connection,

	async start() {
		try {
			await connection.start();

			const patientInteractionStore = usePatientInteractionStore();
			patientInteractionStore.isHubConnected = true;
		} catch (err) {
			console.error(err);
			setTimeout(this.start, 5000);
		}
	},

	async stop() {
		await connection.stop();
	},

	async connectToNewPatientInteraction(patientId: string, interactionId: number) {
		await connection.send("connectToNewPatientInteraction", patientId, interactionId);
	},

	async connectToEditPatientInteraction(patientInteractionId: number) {
		await connection.send("connectToEditPatientInteraction", patientInteractionId);
	},
} as PatientInteractionHub;

export function PatientInteractionHub(Vue: typeof _Vue, options?: any): void {
	Vue.prototype.$PatientInteractionHub = patientInteractionHub;
}
