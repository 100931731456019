
import {
	EmaReminderIntervalsClient,
	ReminderIntervalResult,
	UpdateReminderIntervalsModel,
} from "@/api/LumediApi";
import Vue, { PropType } from "vue";

const reminderClient = new EmaReminderIntervalsClient();

export default Vue.extend({
	props: {
		studyId: String,
		reminderIntervals: Array as PropType<ReminderIntervalResult[]>,
	},

	data() {
		return {
			displayNewDialog: false,
			newInterval: "0:0:00" as string,
		};
	},

	methods: {
		async deleteReminderInterval(reminderInterval: ReminderIntervalResult) {
			await reminderClient.deleteReminderInterval(reminderInterval.id);

			const index = this.reminderIntervals.indexOf(reminderInterval);

			this.reminderIntervals.splice(index, 1);

			this.$toast.add({
				severity: "success",
				summary: "Reminder Interval has been deleted.",
				life: 3000,
			});
		},

		async createReminderInterval() {
			const model = {
				interval: this.newInterval,
			} as UpdateReminderIntervalsModel;

			const createdInterval = await reminderClient.createReminderInterval(this.studyId, model);

			this.reminderIntervals.push(createdInterval);

			this.displayNewDialog = false;
			this.newInterval = "0:0:00";

			this.$toast.add({
				severity: "success",
				summary: "Reminder Interval has been created.",
				life: 3000,
			});
		},
	},
});
