import { render, staticRenderFns } from "./DailyEnrollmentsCard.vue?vue&type=template&id=3b4b7f0f&scoped=true"
import script from "./DailyEnrollmentsCard.vue?vue&type=script&lang=ts"
export * from "./DailyEnrollmentsCard.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4b7f0f",
  null
  
)

export default component.exports