
// Vue
import {
	AccountSummaryResult,
	InteractionAuditsClient,
	PatientInteractionSummaryResult,
} from "@/api/LumediApi";
import utils from "@/utils/utils";
import dayjs from "dayjs";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	name: "agent-audits",
	components: {
		Dropdown,
		DataTable,
		Column,
	},
	setup() {
		const accounts = ref([] as AccountSummaryResult[]);
		const selectedAccountId = ref("all");
		const patientInteractions = ref([] as PatientInteractionSummaryResult[]);
		const studyId = utils.sessionStudyId();

		const accountOptions = computed(() => {
			const returnAccounts = accounts.value.map((x) => ({
				label: `${x.firstName}  ${x.lastName}`,
				value: x.accountId,
			}));
			returnAccounts.push({ label: "- Everyone", value: "all" });

			return returnAccounts;
		});

		const mappedPatientInteractions = computed(() => {
			return patientInteractions.value.map((x) => ({
				uniqueId: x.patient?.uniqueId,
				firstName: x.patient?.firstName,
				lastName: x.patient?.lastName,
				interaction: x.interaction?.name,
				patientId: x.patient?.patientId,
				createdOn: dayjs(x.createdOn.toString() + "Z")
					.tz("America/Toronto")
					.format("YYYY-MM-DD h:mm A"),
			}));
		});

		const showModal = ref(false);

		const displayModal = async () => {
			showModal.value = true;
			const client = new InteractionAuditsClient();
			accounts.value = await client.getAuditableUsers(studyId);
		};

		const selectedAgentUpdated = async () => {
			if (!selectedAccountId.value) return;

			const client = new InteractionAuditsClient();

			patientInteractions.value = await client.getAccountInteractions(
				studyId,
				selectedAccountId.value == "all" ? null : selectedAccountId.value,
			);
		};

		onMounted(async () => {
			const client = new InteractionAuditsClient();
			patientInteractions.value = await client.getAccountInteractions(studyId, null);
		});

		return {
			accounts,
			selectedAccountId,
			patientInteractions,
			mappedPatientInteractions,
			accountOptions,
			showModal,
			displayModal,
			selectedAgentUpdated,
		};
	},
});
