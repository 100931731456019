
import { StudyLanguageApiModel, StudyLanguageResult, StudyLanguagesClient } from "@/api/LumediApi";
import { defineComponent } from "vue";

export default defineComponent({
	data() {
		return {
			studyLanguages: [] as StudyLanguageResult[],
			selectedLanguages: [] as string[],
		};
	},
	async mounted() {
		const apiClient = new StudyLanguagesClient();
		this.studyLanguages = await apiClient.getLanguageSettings();

		this.selectedLanguages = this.studyLanguages
			.filter((x) => x.isEnabled === true)
			.map((x) => x.language.code!);
	},

	computed: {
		isValid(): boolean {
			return this.selectedLanguages.length > 0;
		},
	},

	methods: {
		async save() {
			const apiClient = new StudyLanguagesClient();
			await apiClient.updateLanguages({
				languageCodes: this.selectedLanguages,
			} as StudyLanguageApiModel);

			this.$toast.add({
				severity: "success",
				summary: "Languages have been saved.",
				life: 3000,
			});
		},
	},
});
