
import { QuestionModel, QuestionType } from "@/api/LumediApi";
import { defineComponent } from "vue";
/**
 * Renders a question and its items.
 */
export default defineComponent({
	props: {
		value: {
			type: Object as () => { [k: string]: any | undefined },
			required: true,
		},
		model: {
			type: QuestionModel,
			required: true,
		},
		QuestionComponent: {
			type: Object,
			required: false,
		},
		displayLabels: {
			type: Boolean,
			default: true,
		},
		displayInstructions: {
			type: Boolean,
			default: true,
		},
		textAreaRows: {
			type: Number,
			default: 5,
		},
		textAreaCols: {
			type: Number,
			default: 100,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	methods: {
		getCode(uniqueCode: string | undefined, subCode: string | undefined): string {
			let code = uniqueCode;

			if (subCode != undefined) code = `${code}.${subCode}`;

			return code!;
		},

		updateOverride(e: any) {
			this.$emit("input", e);
		},

		updateLocalValue(code: string, value: any) {
			const updatedValue = { ...this.value };

			updatedValue[code] = value;

			this.$emit("input", updatedValue);
		},
	},
	computed: {
		questionType(): QuestionType {
			return this.model.questionType;
		},

		hasQuestionComponent(): boolean {
			return this.QuestionComponent !== undefined;
		},
	},
});
