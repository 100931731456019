
// Vue
import { defineComponent } from "vue";
// Axios
import axios from "axios";
// moment.js
import utils from "@/utils/utils";
import moment from "moment";

let startDate: string;
let endDate: string;

export default defineComponent({
	name: "weekly-report",

	data: () => ({
		monthlyReportsFile: null,
		physicianReportsFile: null,
		startDate: startDate,
		endDate: endDate,
		studyId: null as string | null,
		isLoading: false,
	}),

	mounted() {
		this.endDate = this.maxEndDate;

		this.studyId = utils.sessionStudyId();

		if (this.studyId == null) {
			console.log("No study id provided");
			return;
		}
	},

	computed: {
		isFromValid(): boolean {
			const date = moment(this.startDate);
			return date.day() === 0;
		},

		isToValid(): boolean {
			const date = moment(this.endDate);
			return date.day() === 6;
		},

		maxEndDate() {
			const todayDayOfWeek = moment().isoWeekday();
			const saturday = 6;

			if (todayDayOfWeek <= saturday) {
				return moment().isoWeekday(saturday).format("YYYY-MM-DD");
			}

			return moment().add(1, "weeks").isoWeekday(saturday).format("YYYY-MM-DD");
		},
	},

	methods: {
		/**
		 * Downloads the Weekly Report file for data between this.startDate and this.endDate.
		 */
		async downloadReport() {
			// Check for empty dates.
			if (!this.startDate || !this.endDate) {
				this.$toast.add({
					severity: "error",
					summary: "Invalid start or end date",
					life: 3000,
				});
			}

			this.isLoading = true;

			// Ensure date strings are formatted as "MM-DD-YYYY".
			const start: string = this.parseDate(this.startDate, "MM-DD-YYYY");
			const end: string = this.parseDate(this.endDate, "MM-DD-YYYY");

			const url = `/api/v1/lumedi-connect-reports/studies/${this.studyId}/weekly?start=${start}&end=${end}`;

			await axios({
				url: url,
				method: "GET",
				responseType: "blob",
			})
				.then((response: any) => {
					const fileURL = window.URL.createObjectURL(new Blob([response.data]));
					const fileLink = document.createElement("a");

					fileLink.href = fileURL;
					fileLink.setAttribute("download", "Weekly Report.xlsx");
					document.body.appendChild(fileLink);

					fileLink.click();
				})
				.catch((error: any) => {
					this.$toast.add({
						severity: "error",
						summary: "Invalid start or end date",
						life: 3000,
					});
					console.log(error);
				});

			this.isLoading = false;
		},

		/**
		 * Parses a date into a provided moment.js string format.
		 * @param date The date to be parsed.
		 * @param format The moment.js format template to parse the date to.
		 * @author Nick Brahimir
		 */
		parseDate(date: Date | string, format: string): string {
			return moment(date).format(format);
		},
	},
});
