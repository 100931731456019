
import utils from "@/utils/utils";
import moment from "moment";
import { defineComponent } from "vue";

import { AttachmentClient, AttachmentModel } from "@/api/LumediApi";
const apiClient = new AttachmentClient();

export default defineComponent({
	props: {
		patientId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			filters: {},
			loading: true,
			attachments: [] as AttachmentModel[],
			canEdit: false,
			canDelete: false,
			studyId: "",
		};
	},
	computed: {
		categories(): (string | undefined)[] {
			return this.attachments
				.map((a) => a.categoryName)
				.filter((value, index, self) => self.indexOf(value) === index);
		},
		statuses(): (string | undefined)[] {
			return this.attachments
				.map((a) => a.status)
				.filter((value, index, self) => self.indexOf(value) === index);
		},
		tags(): (string | undefined)[] {
			return this.attachments
				.flatMap((a) => a.tags)
				.filter((value, index, self) => self.indexOf(value) === index);
		},
	},
	async mounted() {
		try {
			this.studyId = utils.sessionStudyId();
			const response = await apiClient.getAllForPatient(this.studyId, this.patientId);
			if (response.attachments != undefined) {
				this.attachments = response.attachments as AttachmentModel[];
			} else {
				this.attachments = [] as AttachmentModel[];
			}
			this.canEdit = response.canEditTags;
			this.canDelete = response.canDelete;
		} catch (error) {
			this.$toast.add({ severity: "error", summary: "Could not load attachments", life: 3000 });
		}
		this.loading = false;
	},

	methods: {
		async updatAttchment(tags: string[], attachmentID: string, patientID: string) {
			let result;
			try {
				result = await apiClient.updateTags(this.studyId, attachmentID, patientID, tags);
				const attachmentIndex = this.attachments.findIndex((a) => a.attachmentID == attachmentID);
				this.attachments[attachmentIndex].tags = result;
				this.$toast.add({ severity: "success", summary: "Tags updated", life: 3000 });
				return false;
			} catch (e) {
				this.$toast.add({ severity: "error", summary: "Could not update tags", life: 3000 });
			}
		},
		async downloadAttachment(filename: string, attachmentID: string, patientID: string) {
			let result;
			try {
				result = await apiClient.getDownload(this.studyId, patientID, attachmentID);
				const url = window.URL.createObjectURL(new Blob([result.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", filename);
				document.body.appendChild(link);
				link.click();
			} catch (e) {
				this.$toast.add({ severity: "error", summary: "Could not download attachment", life: 3000 });
			}
		},
		async deleteAttachment(attachmentID: string, patientID: string) {
			let result;
			try {
				if (confirm("Are you sure you want to delete this attachment?")) {
					result = await apiClient.deleteAttachment(this.studyId, patientID, attachmentID);
					this.attachments = this.attachments.filter((item) => item.attachmentID != attachmentID);
					this.$toast.add({ severity: "success", summary: "Attachment deleted", life: 3000 });
				}
			} catch (e) {
				this.$toast.add({ severity: "error", summary: "Could not delete attachment", life: 3000 });
			}
		},
		filterDate(value: Date, filter: Date[]): boolean {
			if (value === undefined || value === null) {
				return false;
			}
			if (filter === undefined || filter === null) {
				return true;
			}
			const fromDate = filter[0];
			const toDate = filter[1];
			if (toDate === undefined || toDate === null) {
				return moment(fromDate).startOf("day").isSame(moment(value).startOf("day"));
			} else {
				return moment(value)
					.startOf("day")
					.isBetween(moment(fromDate).startOf("day"), moment(toDate).startOf("day"));
			}
		},
		formatDate(date: Date): string {
			const month = date.getMonth() + 1;
			const day = date.getDate();
			return date.getFullYear() + "-" + month + "-" + day;
		},
	},
});
