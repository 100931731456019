
import QuestionRenderer from "@/components/ae-engine/QuestionRenderer.vue";
import utils from "@/utils/utils";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import ToggleButton from "primevue/togglebutton";
import { computed, defineComponent, onMounted, ref, toRef, watch } from "vue";
import AdverseEventDetailsQuestion from "./complex-questions/AdverseEventDetailsQuestion.vue";
import AdverseEventDetailsRow from "./complex-questions/AdverseEventDetailsRow.vue";
import ComplexNameQuestion from "./complex-questions/ComplexNameQuestion.vue";
import ConcomitantMedicationQuestion from "./complex-questions/ConcomitantMedicationQuestion.vue";
import ConcomitantMedicationRow from "./complex-questions/ConcomitantMedicationRow.vue";
import MailingAddressQuestion from "./complex-questions/MailingAddressQuestion.vue";
import SuspectProductQuestion from "./complex-questions/SuspectProductQuestion.vue";
import SuspectProductRow from "./complex-questions/SuspectProductRow.vue";

import {
	AeInteractionClient,
	PatientAeModel,
	QuestionModel,
	SavePatientInteractionModel,
	SaveQuestionAnswer,
	SaveQuestionAnswerPart,
} from "@/api/LumediApi";

export default defineComponent({
	props: {
		questions: {
			type: Array as () => QuestionModel[],
			default: () => [],
		},
		form: {
			type: Object as () => { [k: string]: { [k: string]: any | undefined } },
			required: true,
		},
		languageCode: {
			type: String,
			default: "en",
		},
	},
	components: {
		QuestionRenderer,
		Accordion,
		AccordionTab,
		ToggleButton,
		ComplexNameQuestion,
		MailingAddressQuestion,
		ConcomitantMedicationQuestion,
		SuspectProductQuestion,
		AdverseEventDetailsQuestion,
		AdverseEventDetailsRow,
		ConcomitantMedicationRow,
		SuspectProductRow,
	},

	setup(props, context) {
		// To control the accordian tabs
		const openTabs = ref([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
		const toggleTabs = ref(true);

		const studyId = utils.sessionStudyId();

		watch(toggleTabs, (currentValue, oldValue) => {
			if (currentValue) {
				openTabs.value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
			} else {
				openTabs.value = [];
			}
		});

		// Generates a lookup of question by uniqueCode
		const questionsByUniqueCode = computed(() => {
			const questionsLookup = {} as { [k: string]: QuestionModel };
			for (const question of props.questions) {
				if (question.uniqueCode === undefined) {
					console.log("Error in AeLayout - question.uniqueCode is undefined");
					continue;
				}
				questionsLookup[question.uniqueCode] = question;
			}
			return questionsLookup;
		});

		const disableSubmitButton = ref(false);

		// Used to track the number of rows to display in the suspect product table.
		const suspectedProductRowsCount = ref(1);
		const suspectedProductAddRowButtonText = ref("Add Row");
		const suspectedProductAddRowButtonClass = ref("p-button-success p-mb-2");
		const disableSuspectedProductAddRowButton = ref(false);
		const suspectedProductArray = ["A", "B", "C"];

		const addSuspectProductRow = () => {
			suspectedProductRowsCount.value++;
			if (suspectedProductRowsCount.value == suspectedProductArray.length) {
				disableSuspectedProductAddRowButton.value = true;
				suspectedProductAddRowButtonText.value = "Max Rows Reached";
				suspectedProductAddRowButtonClass.value = "p-button-danger p-mb-2";
			}
		};

		const suspectedProductDisplay = computed(() => {
			return suspectedProductArray.slice(0, suspectedProductRowsCount.value);
		});

		// Used to track the number of rows to display in the concomitant medication table.
		const concomitantMedicationRowsCount = ref(1);
		const concomitantMedicationAddRowButtonText = ref("Add Row");
		const concomitantMedicationAddRowButtonClass = ref("p-button-success p-mb-2");
		const disableconcomitantMedicationAddRowButton = ref(false);
		const concomitantMedicationArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

		const addConcomitantMedicationRow = () => {
			concomitantMedicationRowsCount.value++;
			if (concomitantMedicationRowsCount.value == concomitantMedicationArray.length) {
				disableconcomitantMedicationAddRowButton.value = true;
				concomitantMedicationAddRowButtonText.value = "Max Rows Reached";
				concomitantMedicationAddRowButtonClass.value = "p-button-danger p-mb-2";
			}
		};

		const concomitantMedicationDisplay = computed(() => {
			return concomitantMedicationArray.slice(0, concomitantMedicationRowsCount.value);
		});

		// Used to track the number of rows to display in the adverse events table.
		const adverseEventDetailsRowCount = ref(1);
		const adverseEventDetailsAddRowButtonText = ref("Add Row");
		const adverseEventDetailsAddRowButtonClass = ref("p-button-success p-mb-2");
		const disableAdverseEventDetailsAddRowButton = ref(false);
		const adverseEventDetailsArray = [1, 2, 3, 4, 5];

		const addAdverseEventDetailsRow = () => {
			adverseEventDetailsRowCount.value++;
			if (adverseEventDetailsRowCount.value == adverseEventDetailsArray.length) {
				disableAdverseEventDetailsAddRowButton.value = true;
				adverseEventDetailsAddRowButtonText.value = "Max Rows Reached";
				adverseEventDetailsAddRowButtonClass.value = "p-button-danger p-mb-2";
			}
		};

		const adverseEventDetailsDisplay = computed(() => {
			return adverseEventDetailsArray.slice(0, adverseEventDetailsRowCount.value);
		});

		// Used to track the number of rows to display in the lab table.
		const labRowCount = ref(1);
		const labAddRowButtonText = ref("Add Row");
		const labAddRowButtonClass = ref("p-button-success p-mb-2");
		const disableLabAddRowButton = ref(false);
		const labArray = [1, 2, 3, 4, 5];

		const addLabRow = () => {
			labRowCount.value++;
			if (labRowCount.value == labArray.length) {
				disableLabAddRowButton.value = true;
				labAddRowButtonText.value = "Max Rows Reached";
				labAddRowButtonClass.value = "p-button-danger p-mb-2";
			}
		};

		const labDisplay = computed(() => {
			return labArray.slice(0, labRowCount.value);
		});

		// Fetch the data for participant in context
		const queryParams = new URLSearchParams(window.location.search);
		const participantId = ref(queryParams.get("p"));

		const updateAnswer = (questionId: number, value: { [k: string]: any | undefined }) => {
			const event = {
				questionId: questionId,
				answer: value,
			};
			context.emit("input", event);
		};

		const patientDetails = ref<PatientAeModel>();

		const fetchPatientDemographics = async () => {
			const client = new AeInteractionClient();
			if (participantId.value) {
				patientDetails.value = await client.getPatientDemographics(studyId, participantId.value);
				const nameQuestionId = questionsByUniqueCode.value["AE.PAT.NAME"].questionID;
				const emailQuestionId = questionsByUniqueCode.value["AE.PAT.EMAIL"].questionID;
				const telephoneQuestionId = questionsByUniqueCode.value["AE.PAT.PHONE"].questionID;
				const ageQuestionId = questionsByUniqueCode.value["AE.PAT.AGE"].questionID;
				const sexQuestionId = questionsByUniqueCode.value["AE.PAT.SEX"].questionID;
				const weightQuestionId = questionsByUniqueCode.value["AE.PAT.WEIGHT"].questionID;
				const heightQuestionId = questionsByUniqueCode.value["AE.PAT.HEIGHT"].questionID;

				//Whenever we call this we need to pass ALL ANSWERS to the questions.
				updateAnswer(nameQuestionId, {
					"AE.AE.PAT.NAME.FIRST": patientDetails.value.firstName,
					"AE.AE.PAT.NAME.MIDDLE": patientDetails.value.middleName,
					"AE.AE.PAT.NAME.LAST": patientDetails.value.lastName,
				});
				updateAnswer(emailQuestionId, {
					"AE.AE.PAT.EMAIL": patientDetails.value.email,
				});
				updateAnswer(telephoneQuestionId, {
					"AE.AE.PAT.PHONE": patientDetails.value.telephone,
				});

				if (patientDetails.value.age != null && patientDetails.value.age !== "") {
					updateAnswer(ageQuestionId, {
						"AE.AE.PAT.AGE": patientDetails.value.age,
					});
				}

				if (patientDetails.value.weight != null && patientDetails.value.weight !== "") {
					updateAnswer(weightQuestionId, {
						"AE.AE.PAT.WEIGHT": patientDetails.value.weight,
					});
				}

				if (patientDetails.value.height != null && patientDetails.value.height !== "") {
					updateAnswer(heightQuestionId, {
						"AE.AE.PAT.HEIGHT": patientDetails.value.height,
					});
				}
				updateAnswer(sexQuestionId, {
					"AE.AE.PAT.SEX": patientDetails.value.sex?.toUpperCase(),
				});
			}
		};

		// Display sections depending on what type of report.
		const reportTypeQuestionId = questionsByUniqueCode.value["AE.REPORT.TYPE"].questionID;
		const reportType = toRef(props.form, reportTypeQuestionId);

		const eventNarrativeRequired = computed(() => {
			return reportType.value["AE.AE.REPORT.TYPE"] !== "TI";
		});

		const issueDescriptionRequired = computed(() => {
			return reportType.value["AE.AE.REPORT.TYPE"] !== "AE";
		});

		watch(reportType, (newVal) => {
			if (newVal["AE.AE.REPORT.TYPE"] === "AE") {
				openTabs.value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 11];
			} else if (newVal["AE.AE.REPORT.TYPE"] === "TI") {
				openTabs.value = [0, 1, 2, 6, 9, 10, 11];
			} else {
				openTabs.value = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
			}
		});

		// Updates the reporter information if the reporter is the patient.
		const patientReporterQuestionId = questionsByUniqueCode.value["AE.PAT.REP"].questionID;
		const patientReporter = toRef(props.form, patientReporterQuestionId);

		watch(patientReporter, (newVal) => {
			const nameQuestionId = questionsByUniqueCode.value["AE.REP.NAME"].questionID;
			const emailQuestionId = questionsByUniqueCode.value["AE.REP.EMAIL"].questionID;
			const telephoneQuestionId = questionsByUniqueCode.value["AE.REP.PHONE"].questionID;

			if (newVal["AE.AE.PAT.REP"] === "YES" && patientDetails.value !== undefined) {
				updateAnswer(nameQuestionId, {
					"AE.AE.REP.NAME.FIRST": patientDetails.value.firstName,
					"AE.AE.REP.NAME.MIDDLE": patientDetails.value.middleName,
					"AE.AE.REP.NAME.LAST": patientDetails.value.lastName,
				});
				updateAnswer(emailQuestionId, {
					"AE.AE.REP.EMAIL": patientDetails.value.email,
				});
				updateAnswer(telephoneQuestionId, {
					"AE.AE.REP.PHONE": patientDetails.value.telephone,
				});
				// Need to check this logic
			} else {
				updateAnswer(nameQuestionId, {
					"AE.AE.REP.NAME.FIRST": undefined,
					"AE.AE.REP.NAME.MIDDLE": undefined,
					"AE.AE.REP.NAME.LAST": undefined,
				});
				updateAnswer(emailQuestionId, { "AE.AE.REP.EMAIL": undefined });
				updateAnswer(telephoneQuestionId, { "AE.AE.REP.PHONE": undefined });
			}
		});

		// If patient refuses consent then clear patient data.
		const consentQuestionId = questionsByUniqueCode.value["AE.CONSENT.REPORT"].questionID;
		const consent = toRef(props.form, consentQuestionId);

		watch(consent, (newVal) => {
			const nameQuestionId = questionsByUniqueCode.value["AE.PAT.NAME"].questionID;
			const emailQuestionId = questionsByUniqueCode.value["AE.PAT.EMAIL"].questionID;
			const telephoneQuestionId = questionsByUniqueCode.value["AE.PAT.PHONE"].questionID;

			if (newVal["AE.AE.CONSENT.REPORT"] === "NO") {
				updateAnswer(nameQuestionId, {
					"AE.AE.PAT.NAME.FIRST": undefined,
					"AE.AE.PAT.NAME.MIDDLE": undefined,
					"AE.AE.PAT.NAME.LAST": undefined,
				});
				updateAnswer(emailQuestionId, {
					"AE.AE.PAT.EMAIL": undefined,
				});
				updateAnswer(telephoneQuestionId, {
					"AE.AE.PAT.PHONE": undefined,
				});
			}
		});

		// If patient consents to contacting physician then add physician data.
		const physicianContactConsentQuestionId =
			questionsByUniqueCode.value["AE.CONTACT.PHYSICIAN"].questionID;
		const physicianContactConsent = toRef(props.form, physicianContactConsentQuestionId);

		watch(physicianContactConsent, (newVal) => {
			const physicianNameQuestionId = questionsByUniqueCode.value["AE.PHYSICIAN.NAME"].questionID;
			const physicianPhoneQuestionId = questionsByUniqueCode.value["AE.PHYSICIAN.PHONE"].questionID;
			const physicianFaxQuestionId = questionsByUniqueCode.value["AE.PHYSICIAN.FAX"].questionID;

			if (newVal["AE.AE.CONTACT.PHYSICIAN"] === "YES") {
				updateAnswer(physicianNameQuestionId, {
					"AE.AE.PHYSICIAN.NAME": `${patientDetails.value?.physicianFirstName} ${patientDetails.value?.physicianLastName}`,
				});
				updateAnswer(physicianPhoneQuestionId, {
					"AE.AE.PHYSICIAN.PHONE": patientDetails.value?.physicianPhone,
				});
				updateAnswer(physicianFaxQuestionId, {
					"AE.AE.PHYSICIAN.FAX": patientDetails.value?.physicianFax,
				});
			}
		});

		const formIsValid = computed(() => {
			//Required fields

			const consentQuestionId = questionsByUniqueCode.value["AE.CONSENT.REPORT"].questionID;
			const consentReport = toRef(props.form, consentQuestionId);

			const initialReceivedQuestionId = questionsByUniqueCode.value["AE.REC.DATE"].questionID;
			const initialReceivedDate = toRef(props.form, initialReceivedQuestionId);

			const dateCompletedQuestionId = questionsByUniqueCode.value["AE.FORM.COMPLETED.DATE"].questionID;
			const dateCompleted = toRef(props.form, dateCompletedQuestionId);

			const eventNarrativeQuestionId = questionsByUniqueCode.value["AE.EVENT.NARRATIVE"].questionID;
			const eventNarrative = toRef(props.form, eventNarrativeQuestionId);

			const issueDescritptionQuestionId = questionsByUniqueCode.value["AE.ISSUE.DECRIPT"].questionID;
			const issueDescription = toRef(props.form, issueDescritptionQuestionId);

			const contactConsentQuestionId = questionsByUniqueCode.value["AE.CONTACT.CONSENT"].questionID;
			const contactConsent = toRef(props.form, contactConsentQuestionId);

			const contactPreferredLanguageQuestionId =
				questionsByUniqueCode.value["AE.PREF.LANGUAGE"].questionID;
			const contactPreferredLanguage = toRef(props.form, contactPreferredLanguageQuestionId);

			const patientDiscussedWithPhysicianQuestionId =
				questionsByUniqueCode.value["AE.PREF.LANGUAGE"].questionID;
			const patientDiscussedWithPhysician = toRef(props.form, patientDiscussedWithPhysicianQuestionId);

			const aeNoteQuestionId = questionsByUniqueCode.value["AE..NOTE"].questionID;
			const aeNote = toRef(props.form, aeNoteQuestionId);

			if (consentReport.value["AE.AE.CONSENT.REPORT"] === undefined) {
				return false;
			}

			if (dateCompleted.value["AE.AE.FORM.COMPLETED.DATE"] === undefined) {
				return false;
			}

			if (initialReceivedDate.value["AE.AE.REC.DATE"] === undefined) {
				return false;
			}

			if (patientReporter.value["AE.AE.PAT.REP"] === undefined) {
				return false;
			}

			if (
				eventNarrativeRequired.value &&
				(eventNarrative.value["AE.AE.EVENT.NARRATIVE"] === undefined ||
					eventNarrative.value["AE.AE.EVENT.NARRATIVE"] === "")
			) {
				return false;
			}

			if (
				issueDescriptionRequired.value &&
				(issueDescription.value["AE.AE.ISSUE.DECRIPT"] === undefined ||
					issueDescription.value["AE.AE.ISSUE.DECRIPT"] === "")
			) {
				return false;
			}

			if (contactConsent.value["AE.AE.CONTACT.CONSENT"] == undefined) {
				return false;
			}

			if (contactPreferredLanguage.value["AE.AE.PREF.LANGUAGE"] == undefined) {
				return false;
			}

			if (patientDiscussedWithPhysician.value["AE.AE.PREF.LANGUAGE"] == undefined) {
				return false;
			}

			if (
				reportType.value["AE.AE.REPORT.TYPE"] === "BOTH" &&
				(issueDescription.value["AE.AE.ISSUE.DECRIPT"] === undefined ||
					issueDescription.value["AE.AE.ISSUE.DECRIPT"] === "") &&
				(eventNarrative.value["AE.AE.EVENT.NARRATIVE"] === undefined ||
					eventNarrative.value["AE.AE.EVENT.NARRATIVE"] === "")
			) {
				return false;
			}

			if (aeNote.value["AE.NOTE"] === undefined || aeNote.value["AE.NOTE"] === "") {
				return false;
			}

			return true;
		});

		const leaveWarning = function (e: any) {
			e.preventDefault();
			e.returnValue = "";
		};

		onMounted(async () => {
			await fetchPatientDemographics();
			window.addEventListener("beforeunload", leaveWarning);
		});

		const formSubmissionFailed = ref(false);

		// Submission of form
		const submitAnswers = async () => {
			if (!formIsValid.value) {
				return;
			}

			disableSubmitButton.value = true;

			const client = new AeInteractionClient();

			const model = {} as SavePatientInteractionModel;

			if (participantId.value !== null) model.participantId = participantId.value;

			model.answers = [] as SaveQuestionAnswer[];

			for (const question in props.form) {
				const questionAnswer = {} as SaveQuestionAnswer;
				questionAnswer.questionId = parseInt(question);
				questionAnswer.parts = [] as SaveQuestionAnswerPart[];

				for (const questionItem in props.form[question]) {
					const questionAnswerPart = {} as SaveQuestionAnswerPart;
					questionAnswerPart.uniqueCode = questionItem;
					questionAnswerPart.subcode = questionItem.substring(questionItem.lastIndexOf(".") + 1);
					questionAnswerPart.value = props.form[question][questionItem];
					questionAnswer.parts.push(questionAnswerPart);
				}
				model.answers.push(questionAnswer);
			}

			// Send interaction to server

			try {
				const result = await client.savePatientInteraction(studyId, model);
				window.removeEventListener("beforeunload", leaveWarning);
				const url = `/study/participant/?id=${participantId.value}`;
				window.location.href = url;
			} catch (error) {
				formSubmissionFailed.value = true;
				disableSubmitButton.value = false;
			}
		};

		// Conditional question section

		const patientGenderQuestionId = questionsByUniqueCode.value["AE.PAT.SEX"].questionID;
		const patientGender = toRef(props.form, patientGenderQuestionId);
		const disablePregnancyQuestion = computed(() => {
			return patientGender.value["AE.AE.PAT.SEX"] !== "FEMALE";
		});

		const reporterHcpQuestionId = questionsByUniqueCode.value["AE.REP.HCP"].questionID;
		const reporterHcp = toRef(props.form, reporterHcpQuestionId);
		const disableReporterHcp = computed(() => {
			return reporterHcp.value["AE.AE.REP.HCP"] !== "YES";
		});

		const reportedAuthorityQuestionId = questionsByUniqueCode.value["AE.REPORTED.AUTH"].questionID;
		const reportedAuthority = toRef(props.form, reportedAuthorityQuestionId);
		const disableReportedAuthorityDate = computed(() => {
			return reportedAuthority.value["AE.AE.REPORTED.AUTH"] !== "YES";
		});

		const labTestsToReportQuestionId = questionsByUniqueCode.value["AE.LAB.TESTS.TO.REPORT"].questionID;
		const labTestsToReport = toRef(props.form, labTestsToReportQuestionId);
		const disableLabData = computed(() => {
			return labTestsToReport.value["AE.AE.LAB.TESTS.TO.REPORT"] !== "YES";
		});

		const productReturnQuestionId = questionsByUniqueCode.value["AE.TESTING"].questionID;
		const productReturn = toRef(props.form, productReturnQuestionId);
		const displayAddressMessage = computed(() => {
			return productReturn.value["AE.AE.TESTING"] === "YES";
		});

		const physicianContactDetails = questionsByUniqueCode.value["AE.CONTACT.PHYSICIAN"].questionID;
		const contactPhysician = toRef(props.form, physicianContactDetails);
		const disablePhysicianContact = computed(() => {
			return contactPhysician.value["AE.AE.CONTACT.PHYSICIAN"] !== "YES";
		});

		const urgentCareDetails = questionsByUniqueCode.value["AE.URGENT.CARE"].questionID;
		const urgentCare = toRef(props.form, urgentCareDetails);
		const disableUrgentCare = computed(() => {
			return urgentCare.value["AE.AE.URGENT.CARE"] !== "YES";
		});

		const hospitalizationDetails = questionsByUniqueCode.value["AE.HOSPITALIZATION"].questionID;
		const hospitalization = toRef(props.form, hospitalizationDetails);
		const disableHospitalization = computed(() => {
			return hospitalization.value["AE.AE.HOSPITALIZATION"] !== "YES";
		});

		const deathDetails = questionsByUniqueCode.value["AE.DEATH"].questionID;
		const death = toRef(props.form, deathDetails);
		const disableDeath = computed(() => {
			return death.value["AE.AE.DEATH"] !== "YES";
		});

		const isLanguageFrench = computed(() => {
			return props.languageCode === "fr" || props.languageCode === "French";
		});

		return {
			openTabs,
			questionsByUniqueCode,
			toggleTabs,
			suspectedProductRowsCount,
			updateAnswer,
			addSuspectProductRow,
			ComplexNameQuestion,
			MailingAddressQuestion,
			ConcomitantMedicationQuestion,
			SuspectProductQuestion,
			AdverseEventDetailsQuestion,
			suspectedProductDisplay,
			disableSuspectedProductAddRowButton,
			suspectedProductAddRowButtonText,
			suspectedProductAddRowButtonClass,
			concomitantMedicationAddRowButtonText,
			concomitantMedicationAddRowButtonClass,
			concomitantMedicationDisplay,
			disableconcomitantMedicationAddRowButton,
			addConcomitantMedicationRow,
			adverseEventDetailsAddRowButtonText,
			adverseEventDetailsAddRowButtonClass,
			adverseEventDetailsDisplay,
			disableAdverseEventDetailsAddRowButton,
			addAdverseEventDetailsRow,
			labAddRowButtonText,
			disableLabData,
			labAddRowButtonClass,
			labDisplay,
			disableLabAddRowButton,
			addLabRow,
			fetchPatientDemographics,
			submitAnswers,
			disableSubmitButton,
			patientReporter,
			disableReportedAuthorityDate,
			reportedAuthority,
			disablePhysicianContact,
			contactPhysician,
			formIsValid,
			urgentCare,
			disableUrgentCare,
			hospitalization,
			disableHospitalization,
			death,
			disableDeath,
			patientGender,
			disablePregnancyQuestion,
			reporterHcp,
			disableReporterHcp,
			displayAddressMessage,
			patientDetails,
			eventNarrativeRequired,
			issueDescriptionRequired,
			formSubmissionFailed,
			isLanguageFrench,
		};
	},
});
