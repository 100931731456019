
import { PatientChartClient, PatientInfoResult } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Skeleton from "primevue/skeleton";
import { computed, defineComponent, onMounted, ref } from "vue";
export default defineComponent({
	name: "patient-info-card",
	components: { Skeleton },
	setup() {
		const studyId = ref(utils.sessionStudyId());

		const queryParams = new URLSearchParams(window.location.search);
		const patientId = ref(queryParams.get("p"));

		const patientInfoResponse = ref(undefined as PatientInfoResult | undefined);
		const isLoading = ref(false);
		const loadingError = ref(false);

		onMounted(async () => {
			if (patientId.value == null) {
				loadingError.value = true;
				return;
			}

			isLoading.value = true;

			const client = new PatientChartClient();

			try {
				patientInfoResponse.value = await client.getPatientInfo(studyId.value, patientId.value);
			} catch (error) {
				loadingError.value = true;
			}

			isLoading.value = false;
		});

		/**
		 * The name of the patient in the format of "Last, First"
		 * Returns an empty string if the patient isn't loaded.
		 */
		const patientName = computed(() => {
			if (patientInfoResponse.value === undefined) return "";

			return `${patientInfoResponse.value.lastName}, ${patientInfoResponse.value.firstName}`;
		});

		/**
		 * The unique identifier of the patient.
		 * Returns an empty string if the patient isn't loaded.
		 */
		const patientUniqueId = computed(() => {
			if (patientInfoResponse.value === undefined) return "";

			return patientInfoResponse.value.uniqueId;
		});

		return {
			patientId,
			studyId,
			patientName,
			patientUniqueId,
			isLoading,
			loadingError,
		};
	},
});
