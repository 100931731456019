
// TODO: Label for id in template

import InputNumber from "primevue/inputnumber";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: Number,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		InputNumber,
	},
	computed: {
		localValue: {
			get(): number | undefined {
				return this.value;
			},
			set(value: number | undefined) {
				this.$emit("input", value);
			},
		},

		metricHeight(): number | undefined {
			return this.localValue;
		},

		imperialHeightFeet: {
			get(): number | undefined {
				if (this.metricHeight == undefined || (this.metricHeight as any) == "") return undefined;

				let inches = this.metricHeight / 2.54;
				let feet = Math.floor(inches / 12);
				inches = Math.round(inches - feet * 12);

				if (inches === 12) {
					feet += 1;
				}

				return feet;
			},
			set(value: number | undefined) {
				if (value === undefined) {
					this.localValue = undefined;
					return;
				}

				if (value < 0) {
					value = 0;
				}

				const inches = this.imperialHeightInches ?? 0;
				const metric = Math.round(((value * 12 + inches) * 2.54 * 10) / 10);

				this.localValue = metric;
			},
		},

		imperialHeightInches: {
			get(): number | undefined {
				if (
					this.metricHeight === undefined ||
					(this.metricHeight as any) == "" ||
					this.metricHeight === null
				)
					return undefined;

				let inches = this.metricHeight / 2.54;
				let feet = Math.floor(inches / 12);
				inches = Math.round(inches - feet * 12);

				if (inches === 12) {
					feet += 1;
					inches = 0;
				}

				return inches;
			},
			set(value: number | undefined) {
				if (value === undefined) {
					this.localValue = undefined;
					return;
				}

				if (value < 0) {
					value = 0;
				}

				const feet = this.imperialHeightFeet ?? 0;
				const metric = Math.round(((feet * 12 + value) * 2.54 * 10) / 10);

				this.localValue = metric;
			},
		},
	},
});
