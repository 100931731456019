
import { FaxNumbersClient, IStudyFaxNumberInfo, IUpdateFaxModel, UpdateFaxModel } from "@/api/LumediApi";
import utils from "@/utils/utils";
import ConfirmPopup from "primevue/confirmpopup";
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";

interface Editable {
	isEdit: boolean;
	isNew: boolean;
}

class EditableItem<T> implements Editable {
	isEdit = false;
	isNew = false;
	model: T;
	/**
	 *
	 */
	constructor(data: T, isNew = false) {
		this.model = data;
		this.isNew = isNew;
	}
}

interface StudyFaxNumberInfoWithStudy extends IStudyFaxNumberInfo {
	studyId: string;
}

class Errors extends Map<string, string> {}

export default defineComponent({
	setup: () => {
		const confirm = ConfirmPopup;
		const v = getCurrentInstance()!.proxy.$root;
		const client = new FaxNumbersClient();
		const items = ref([] as EditableItem<StudyFaxNumberInfoWithStudy>[]);
		const errors = ref(new Errors());

		const createNew = (): EditableItem<StudyFaxNumberInfoWithStudy> =>
			new EditableItem(
				{
					studyId: utils.sessionStudyId(),
					faxNumberId: "",
					faxNumber: "",
					label: "",
				} as StudyFaxNumberInfoWithStudy,
				true,
			);
		const current = ref(createNew());

		//methods
		const isValid = (item: IStudyFaxNumberInfo): boolean => {
			errors.value = new Errors();

			if (!item.faxNumber) errors.value.set("fax", "Fax number is empty!");
			if (!item.label) errors.value.set("label", "Label is empty!");

			return errors.value.size == 0;
		};

		const edit = (item: EditableItem<StudyFaxNumberInfoWithStudy>) => {
			current.value = item;
		};
		const save = async () => {
			if (!isValid(current.value.model)) return;

			try {
				if (current.value.isNew) {
					const id = await client.create(
						new UpdateFaxModel(current.value.model as unknown as IUpdateFaxModel),
					);
					v.$toast.add({
						severity: "success",
						summary: "Operation was successful",
						detail: "Fax number saved",
						life: 3000,
					});
					current.value.model.faxNumberId = id;
					items.value.push(current.value);
					current.value.isNew = false;
				} else {
					await client.update(
						current.value.model.faxNumberId,
						new UpdateFaxModel(current.value.model as unknown as IUpdateFaxModel),
					);
					v.$toast.add({
						severity: "success",
						summary: "Operation was successful",
						detail: "Fax number saved",
						life: 3000,
					});
				}
				reset();
			} catch (ex: any) {
				serverError();
			}
		};

		const serverError = () =>
			v.$toast.add({
				severity: "error",
				summary: "Error communicating with the server",
				life: 3000,
			});
		const del = (item: EditableItem<IStudyFaxNumberInfo>, evnt: Event) => {
			v.$confirm.require({
				target: evnt.target,
				message: `Are you sure you want to delete this number '${item.model.faxNumber}'?`,
				icon: "pi pi-exclamation-triangle",
				acceptClass: "p-button-danger",
				accept: async () => {
					//callback to execute when user confirms the action
					try {
						await client.delete(item.model.faxNumberId);
						//remove deleted
						items.value = items.value.filter((d) => d.model.faxNumberId != item.model.faxNumberId);
						v.$toast.add({
							severity: "success",
							summary: "Operation was successful",
							detail: "Fax number deleted",
							life: 3000,
						});
					} catch (ex: any) {
						serverError();
					}
				},
				reject: () => {},
			});
		};

		const reset = () => {
			current.value = current.value = createNew();
			errors.value = new Errors();
		};

		onMounted(async () => {
			const data = await client.get(utils.sessionStudyId());
			items.value = data.map((d) => {
				const t = d as any;
				t.studyId = utils.sessionStudyId();
				return new EditableItem(t as StudyFaxNumberInfoWithStudy);
			});
		});

		return {
			edit,
			save,
			del,
			reset,
			errors,
			current,
			items,
		};
	},
});
