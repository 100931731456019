
import {
	InteractionSummaryResult,
	InteractionsClient,
	StudyJourneyConfigurationClient,
} from "@/api/LumediApi";
import utils from "@/utils/utils";
import { defineComponent, ref } from "vue";
export default defineComponent({
	name: "interaction-export-json",
	components: {},
	setup(props) {
		const studyId = ref(utils.sessionStudyId());

		const queryParams = new URLSearchParams(window.location.search);

		const isLoading = ref(false);
		const loadingError = ref(false);
		const exportString = ref("");
		const displayDialog = ref(false);

		const interactions = ref([] as InteractionSummaryResult[]);

		const openExport = async () => {
			isLoading.value = true;

			const client = new StudyJourneyConfigurationClient();
			interactions.value = await client.getInteractions(studyId.value);

			displayDialog.value = true;

			isLoading.value = false;
		};

		const interactionId = ref(undefined as number | undefined);

		const loadExport = async () => {
			if (interactionId.value === undefined) return;

			isLoading.value = true;

			const client = new InteractionsClient();
			const exportData = await client.getJsonExport(interactionId.value);
			exportString.value = exportData.json ?? "";
			displayDialog.value = true;

			isLoading.value = false;
		};

		const exportInput = ref(null as any);
		const copyToClipboard = () => {
			exportInput.value.select();
			exportInput.value.setSelectionRange(0, exportString.value.length);
			document.execCommand("copy");
		};

		return {
			isLoading,
			loadExport,
			exportString,
			displayDialog,
			copyToClipboard,
			openExport,
			interactions,
			interactionId,
			exportInput,
		};
	},
});
