
import { StudySettingsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import { computed, defineComponent, onMounted, ref } from "vue";
export default defineComponent({
	name: "study-brand-bar",
	components: {},
	setup(props, context) {
		const studyId = ref(utils.sessionStudyId());
		const primaryColor = ref("");

		onMounted(async () => {
			const client = new StudySettingsClient();
			const branding = await client.getStudyBranding(studyId.value);
			primaryColor.value = branding.primaryColor ?? "";
		});

		const cssColor = computed(() => {
			return {
				"background-color": `#${primaryColor.value}`,
			};
		});

		return {
			studyId,
			primaryColor,

			cssColor,
		};
	},
});
