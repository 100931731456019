
import { PatientSummaryResult, ReportsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	name: "UnmatchedPhysiciansReport",
	components: {
		Dropdown,
		DataTable,
		Column,
	},
	setup() {
		const showModal = ref(false);
		const studyId = utils.sessionStudyId();
		const reportsClient = new ReportsClient();
		const unmatchedPhysiciansDetails = ref([] as PatientSummaryResult[]);
		const loadingTableData = ref(false);

		const patientsWithoutPhysicianCount = computed(() => {
			return unmatchedPhysiciansDetails.value.length ?? 0;
		});

		onMounted(async () => {
			try {
				const result = await reportsClient.getPatientsWithNoSelectedPhysician(studyId);
				unmatchedPhysiciansDetails.value = result;
			} catch (error) {
				console.log("Error retreiving unmatched Physicians count", error);
			}
		});

		return {
			unmatchedPhysiciansDetails,
			showModal,
			loadingTableData,
			patientsWithoutPhysicianCount,
		};
	},
});
