export default abstract class AxiosClient {
	public constructor() {}

	protected transformOptions(options: any) {
		const accessToken = sessionStorage.getItem("access_token");

		// Add headers
		options.headers["Authorization"] = `Bearer ${accessToken}`;
		options.headers["X-Tenant-Key"] = sessionStorage.getItem("client_id");

		return Promise.resolve(options);
	}
}
