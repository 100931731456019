
import { InteractionJsonImportModel, InteractionsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Message from "primevue/message";
import { defineComponent, getCurrentInstance, ref } from "vue";
export default defineComponent({
	name: "interaction-import-json",
	components: { Message },
	setup(props) {
		const v = getCurrentInstance()!.proxy.$root;
		const studyId = ref(utils.sessionStudyId());
		const isLoading = ref(false);
		const loadingError = ref(false);
		const importString = ref("");
		const displayDialog = ref(false);
		const variablePrefix = ref("");
		const importInteraction = async () => {
			if (importString.value === undefined) return;

			loadingError.value = false;
			isLoading.value = true;

			try {
				const client = new InteractionsClient();
				await client.importJson({
					studyId: studyId.value,
					variablePrefix: variablePrefix.value,
					json: importString.value,
				} as InteractionJsonImportModel);

				v.$toast.add({ severity: "success", summary: "Interaction Imported", life: 3000 });

				displayDialog.value = false;
			} catch (error) {
				loadingError.value = true;
			}

			isLoading.value = false;
		};

		return {
			isLoading,
			importInteraction,
			importString,
			displayDialog,
			variablePrefix,
			loadingError,
		};
	},
});
