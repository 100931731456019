
import { patientInteractionHub } from "@/hubs/patientInteractionHub";
import { PatientInteractionAction, usePatientInteractionStore } from "@/store/patientInteractionStore";
import Message from "primevue/message";
import { computed, defineComponent, onMounted, ref } from "vue";

export default defineComponent({
	components: {
		Message,
	},
	props: {},
	setup(props, context) {
		const store = usePatientInteractionStore();
		const queryParams = new URLSearchParams(window.location.search);
		const participantId = ref(queryParams.get("p"));

		store.patientId = participantId.value;

		const queryInteractionId = queryParams.get("i");
		const interactionId = ref(null as number | null);

		if (queryInteractionId) {
			interactionId.value = Number.parseInt(queryInteractionId);
			store.interactionId = interactionId.value;
		}

		const querySuperId = ref(queryParams.get("superId"));
		if (querySuperId.value) {
			store.patientInteractionId = Number.parseInt(querySuperId.value);
		}

		onMounted(async () => {
			await patientInteractionHub.start();
			if (store.patientInteractionId) {
				await patientInteractionHub.connectToEditPatientInteraction(store.patientInteractionId);
			} else if (participantId.value && interactionId.value) {
				await patientInteractionHub.connectToNewPatientInteraction(
					participantId.value,
					interactionId.value,
				);
			}
		});

		const isEditing = computed(() => {
			return store.patientInteractionAction === PatientInteractionAction.Edit;
		});

		return {
			interactionId,
			participantId,
			store,
			isEditing,
		};
	},
});
