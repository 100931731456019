
import { ConsultationServiceSummaryResult, StudyParticipantsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Skeleton from "primevue/skeleton";
import { defineComponent, ref } from "vue";

export default defineComponent({
	name: "ConsultationServiceStatusCard",
	components: {
		Skeleton,
	},
	setup() {
		const summary = ref(new ConsultationServiceSummaryResult());
		const isLoading = ref(false);
		const isApiError = ref(false);

		const loadSummary = async () => {
			isLoading.value = true;
			isApiError.value = false;
			try {
				const studyParticipantsClient = new StudyParticipantsClient();
				const studyId = utils.sessionStudyId();
				summary.value = await studyParticipantsClient.getConsultationTypeSummary(studyId);
			} catch {
				isApiError.value = true;
			} finally {
				isLoading.value = false;
			}
		};

		loadSummary();

		return {
			summary,
			isLoading,
			isApiError,
		};
	},
});
