
import {
	PatientSummaryResult,
	StudyParticipantsClient,
	UpdateStudyPspSystemPatientModel,
} from "@/api/LumediApi";
import utils from "@/utils/utils";
import Skeleton from "primevue/skeleton";
import { defineComponent } from "vue";
const studyParticipantsClient = new StudyParticipantsClient();

export default defineComponent({
	components: {
		Skeleton,
	},
	data() {
		return {
			filters: {},
			selectedPatientId: null as string | null,
			patients: [] as PatientSummaryResult[],
			isSaving: false,
			isLoading: true,
			studyId: "",
		};
	},

	async mounted() {
		this.studyId = utils.sessionStudyId();

		const systemPatientResponse = await studyParticipantsClient.getStudyPspSystemPatient(this.studyId);

		if (systemPatientResponse.systemPatient != null)
			this.selectedPatientId = systemPatientResponse.systemPatient.patientId;

		this.patients = await studyParticipantsClient.getParticipants(this.studyId);

		this.isLoading = false;
	},

	computed: {
		patientDisplayValue(): string | undefined {
			if (this.selectedPatientId == null) return "";

			const patient = this.patients.find((x) => x.patientId === this.selectedPatientId);

			if (patient === undefined) return "";

			return `${patient.uniqueId} - ${patient.firstName} ${patient.lastName}`;
		},
	},

	methods: {
		async onPatientSelect(event: any) {
			// @ts-ignore
			this.$refs.patientsPanel.hide();

			this.selectedPatientId = event.data.patientId;
		},

		togglePatientsPanel(event: any) {
			// @ts-ignore
			this.$refs.patientsPanel.toggle(event);
		},

		async save() {
			this.isSaving = true;

			// Save the new system patient.
			await studyParticipantsClient.updateStudyPspSystemPatient(this.studyId, {
				patientId: this.selectedPatientId,
			} as UpdateStudyPspSystemPatientModel);

			this.isSaving = false;

			this.$toast.add({ severity: "success", summary: "Saved", life: 3000 });
		},
	},
});
