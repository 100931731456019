
import {
	PatientChartClient,
	PatientsClient,
	ReimbursementJourneyStatus,
	SaxendaPatientRegistrationResult,
	StiEnrollmentCategoryStatus,
	StiEnrollmentState,
} from "@/api/LumediApi";
import utils from "@/utils/utils";
import dayjs from "dayjs";
import Card from "primevue/card/Card";
import InputSwitch from "primevue/inputswitch/InputSwitch";
import Message from "primevue/message";
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import ServicesBannerConsultationType from "./ServicesBannerConsultationType.vue";
import ServicesBannerIndicator from "./ServicesBannerIndicator.vue";

export default defineComponent({
	name: "saxenda-services-banner",
	components: { Card, InputSwitch, Message, ServicesBannerConsultationType, ServicesBannerIndicator },
	setup() {
		const v = getCurrentInstance()!.proxy.$root;
		if (!v) throw new Error("Vue instance not found");
		const studyId = ref(utils.sessionStudyId());
		const participantId = ref<string | null>();

		const registeredForQuickMedicationSupport = ref<boolean | undefined>();
		const registeredForCompleteMedicationSupport = ref<boolean | undefined>();
		const registeredForReimbursementServiceSelection = ref<boolean | undefined>();
		const isLegacy = ref(false);

		const uciCode = ref<string | undefined>();

		const registeredForDiscountServiceSelection = ref<boolean | undefined>();

		// The threshold of minutes before an in-complete enrollment is considered in an error state.
		const errorThresholdMinutes = 30;

		const resendCode = async () => {
			const patientClient = new PatientsClient();

			if (!participantId.value) return;
			await patientClient.resendDiscountCode(studyId.value, participantId.value);

			v.$toast.add({ severity: "success", summary: "Email Sent", life: 3000 });
		};

		const apiServicesResponse = ref(undefined as SaxendaPatientRegistrationResult | undefined);

		onMounted(async () => {
			const client = new PatientChartClient();

			const queryParams = new URLSearchParams(window.location.search);

			participantId.value = queryParams.get("id");

			if (!participantId.value) return;

			apiServicesResponse.value = await client.getPatientRegistration(
				studyId.value,
				participantId.value,
			);

			registeredForQuickMedicationSupport.value =
				apiServicesResponse.value.registeredForQuickMedicationSupport;
			registeredForCompleteMedicationSupport.value =
				apiServicesResponse.value.registeredForCompleteMedicationSupport;

			registeredForReimbursementServiceSelection.value =
				apiServicesResponse.value.registeredForReimbursementService;
			registeredForDiscountServiceSelection.value =
				apiServicesResponse.value.registeredForDiscountService;

			isLegacy.value = apiServicesResponse.value.isLegacy;

			uciCode.value = apiServicesResponse.value.discountUciCode;
		});

		const stiEnrollmentStatus = computed(() => {
			if (apiServicesResponse.value === undefined) return undefined;

			switch (apiServicesResponse.value.stiEnrollmentStatus) {
				case StiEnrollmentState.NotEnrolled:
					return undefined;
				case StiEnrollmentState.Requested:
					return "Requested";
				case StiEnrollmentState.Pending:
					return "Pending";
				case StiEnrollmentState.Processing:
					return "Processing";
				case StiEnrollmentState.Completed:
					return "Completed";
			}

			return undefined;
		});

		const stiEnrollmentClass = computed(() => {
			if (apiServicesResponse.value === undefined) return undefined;

			if (apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Pending) {
				let compareDate: Date | undefined = undefined;

				if (
					apiServicesResponse.value.stiDiscountStatus ===
					StiEnrollmentCategoryStatus.PendingInitialEnrollment
				) {
					compareDate = apiServicesResponse.value.stiDiscountEnabledOn;
				} else if (
					apiServicesResponse.value.stiReimbursementStatus ===
					StiEnrollmentCategoryStatus.PendingInitialEnrollment
				) {
					compareDate = apiServicesResponse.value.stiReimbursementEnabledOn;
				}

				const timeDiffMinutes = dayjs(dayjs()).diff(compareDate, "minute");

				if (timeDiffMinutes >= errorThresholdMinutes) return "enrollment-internal-issue";

				return "custom-chip-in-progress";
			} else if (
				apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Requested ||
				apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Processing
			) {
				const timeDiffMinutes = dayjs(dayjs()).diff(
					apiServicesResponse.value.stiEnrollmentRequestedOn,
					"minute",
				);

				if (timeDiffMinutes >= errorThresholdMinutes) return "enrollment-third-party-issue";

				return "custom-chip-in-progress";
			}

			switch (apiServicesResponse.value.stiEnrollmentStatus) {
				case StiEnrollmentState.NotEnrolled:
					return "custom-chip-in-unregistered";
				case StiEnrollmentState.Completed:
					return "custom-chip-registered";
			}
		});

		const discountEnrollmentStatus = computed(() => {
			if (apiServicesResponse.value === undefined) return undefined;

			switch (apiServicesResponse.value.stiDiscountStatus) {
				case StiEnrollmentCategoryStatus.NotEnrolled:
					return "Not Enrolled";
				case StiEnrollmentCategoryStatus.Pending:
					return "Pending Update";
				case StiEnrollmentCategoryStatus.PendingInitialEnrollment:
					return "Pending";
				case StiEnrollmentCategoryStatus.Enrolled:
					return "Enrolled";
			}
			return undefined;
		});

		const discountEnrollmentClass = computed(() => {
			if (apiServicesResponse.value === undefined) return undefined;

			// If the enrollment was enabled, and the state is pending (no enrollment has been sent to STI), and it's been longer than a time threshold. Indicate it's an internal issue.
			if (
				apiServicesResponse.value.stiDiscountEnabledOn !== undefined &&
				apiServicesResponse.value.stiDiscountStatus ===
					StiEnrollmentCategoryStatus.PendingInitialEnrollment &&
				apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Pending
			) {
				const discountTimeDiffMinutes = dayjs(dayjs()).diff(
					apiServicesResponse.value.stiDiscountEnabledOn,
					"minute",
				);

				if (discountTimeDiffMinutes >= errorThresholdMinutes) return "enrollment-internal-issue";

				return "custom-chip-in-progress";
			}

			if (
				apiServicesResponse.value.stiEnrollmentRequestedOn !== undefined &&
				apiServicesResponse.value.stiDiscountStatus ===
					StiEnrollmentCategoryStatus.PendingInitialEnrollment &&
				(apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Requested ||
					apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Processing)
			) {
				const discountTimeDiffMinutes = dayjs(dayjs()).diff(
					apiServicesResponse.value.stiEnrollmentRequestedOn,
					"minute",
				);

				if (discountTimeDiffMinutes >= errorThresholdMinutes) return "enrollment-third-party-issue";

				return "custom-chip-in-progress";
			}

			switch (apiServicesResponse.value.stiDiscountStatus) {
				case StiEnrollmentCategoryStatus.NotEnrolled:
					return "custom-chip-unregistered";
				case StiEnrollmentCategoryStatus.Pending:
					return "custom-chip-in-progress";
				case StiEnrollmentCategoryStatus.PendingInitialEnrollment:
					return "custom-chip-in-progress";
				case StiEnrollmentCategoryStatus.Enrolled:
					return "custom-chip-registered";
			}
			return undefined;
		});

		const reimbursementEnrollmentStatus = computed(() => {
			if (apiServicesResponse.value === undefined) return undefined;

			switch (apiServicesResponse.value.stiReimbursementStatus) {
				case StiEnrollmentCategoryStatus.NotEnrolled:
					return "Not Enrolled";
				case StiEnrollmentCategoryStatus.Pending:
					return "Pending Update";
				case StiEnrollmentCategoryStatus.PendingInitialEnrollment:
					return "Pending";
				case StiEnrollmentCategoryStatus.Enrolled:
					return "Enrolled";
			}
			return undefined;
		});

		const reimbursementEnrollmentClass = computed(() => {
			if (apiServicesResponse.value === undefined) return undefined;

			// Determine internal error case for reimbursement initial enrollment.
			if (
				apiServicesResponse.value.stiReimbursementEnabledOn !== undefined &&
				apiServicesResponse.value.stiReimbursementStatus ===
					StiEnrollmentCategoryStatus.PendingInitialEnrollment &&
				apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Pending
			) {
				const timeDiffMinutes = dayjs(dayjs()).diff(
					apiServicesResponse.value.stiReimbursementEnabledOn,
					"minute",
				);

				if (timeDiffMinutes >= errorThresholdMinutes) return "enrollment-internal-issue";

				return "custom-chip-in-progress";
			}

			// Determine STI error case for reimbursement initial enrollment.
			if (
				apiServicesResponse.value.stiEnrollmentRequestedOn !== undefined &&
				apiServicesResponse.value.stiReimbursementStatus ===
					StiEnrollmentCategoryStatus.PendingInitialEnrollment &&
				(apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Requested ||
					apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Processing)
			) {
				const timeDiffMinutes = dayjs(dayjs()).diff(
					apiServicesResponse.value.stiReimbursementEnabledOn,
					"minute",
				);

				if (timeDiffMinutes >= errorThresholdMinutes) return "enrollment-third-party-issue";

				return "custom-chip-in-progress";
			}

			// Determine STI error case for reimbursement update enrollment.
			if (
				apiServicesResponse.value.stiEnrollmentRequestedOn !== undefined &&
				apiServicesResponse.value.stiReimbursementStatus === StiEnrollmentCategoryStatus.Pending &&
				apiServicesResponse.value.stiEnrollmentStatus === StiEnrollmentState.Completed
			) {
				let dateToCompare = apiServicesResponse.value.stiEnrollmentProcessCompletedOn;

				// Use the later date as the comparison.
				if (dayjs(dateToCompare).isBefore(apiServicesResponse.value.stiReimbursementEnabledOn)) {
					dateToCompare = apiServicesResponse.value.stiReimbursementEnabledOn;
				}

				const timeDiffMinutes = dayjs(dayjs()).diff(dateToCompare, "minute");

				if (timeDiffMinutes >= errorThresholdMinutes) return "enrollment-third-party-issue";

				return "custom-chip-in-progress";
			}

			switch (apiServicesResponse.value.stiReimbursementStatus) {
				case StiEnrollmentCategoryStatus.NotEnrolled:
					return "custom-chip-unregistered";
				case StiEnrollmentCategoryStatus.Pending:
					return "custom-chip-in-progress";
				case StiEnrollmentCategoryStatus.PendingInitialEnrollment:
					return "custom-chip-in-progress";
				case StiEnrollmentCategoryStatus.Enrolled:
					return "custom-chip-registered";
			}
			return undefined;
		});

		const isStiEnrollmentValid = computed(() => {
			return apiServicesResponse.value?.isEnrollmentValid === true;
		});

		const stiEnrollmentErrorMessage = computed(() => {
			return apiServicesResponse.value?.enrollmentErrorMessage;
		});

		const isDiscountBenefitClaimed = computed(() => {
			return apiServicesResponse.value?.isBenefitClaimed === true;
		});

		const reimbursementJourneyStatus = computed(() => {
			if (apiServicesResponse.value === undefined) return ReimbursementJourneyStatus.Unknown;

			return apiServicesResponse.value.reimbursementJourneyStatus;
		});

		const reimbursementJourneyStatusText = computed(() => {
			switch (reimbursementJourneyStatus.value) {
				case ReimbursementJourneyStatus.Unknown:
					return "Unknown";
				case ReimbursementJourneyStatus.AwaitingInitialContact:
					return "Awaiting Initial Contact";
				case ReimbursementJourneyStatus.InProgress:
					return "In Progress";
				case ReimbursementJourneyStatus.Abandoned:
					return "Abandoned";
				case ReimbursementJourneyStatus.DinIsABenefit:
					return "DIN is a benefit";
				case ReimbursementJourneyStatus.DinIsNotABenefit:
					return "DIN is not a benefit";
				case ReimbursementJourneyStatus.SaPending:
					return "SA Pending";
				case ReimbursementJourneyStatus.SaApproved:
					return "SA Approved";
				case ReimbursementJourneyStatus.SaDenied:
					return "SA Denied";
				case ReimbursementJourneyStatus.SaAppeal:
					return "SA Appeal";
			}
		});

		const reimbursementJourneyDescription = computed(() => {
			switch (reimbursementJourneyStatus.value) {
				case ReimbursementJourneyStatus.AwaitingInitialContact:
					return "Patient has yet to book initial appt with STI Reimbursement Specialist, or initial appt is booked and not yet completed";
				case ReimbursementJourneyStatus.InProgress:
					return "Contact between Patient and STI Reimbursement Specialist has been made, pending further information or completion of Special Authorization form";
				case ReimbursementJourneyStatus.Abandoned:
					return "Patient has declined service or unreachable after 3 call attempts";
				case ReimbursementJourneyStatus.DinIsABenefit:
					return "Patient's insurance plan covers Saxenda (Special Authorization not required)";
				case ReimbursementJourneyStatus.DinIsNotABenefit:
					return "Patient's insurance plan does not cover Saxenda (no Special Authorization form)";
				case ReimbursementJourneyStatus.SaPending:
					return "Special Authorization form awaiting result from insurer";
				case ReimbursementJourneyStatus.SaApproved:
					return "Special Authorization form approved by insurer";
				case ReimbursementJourneyStatus.SaDenied:
					return "Special Authorization form denied by insurer";
				case ReimbursementJourneyStatus.SaAppeal:
					return "Patient is appealing insurer decision to deny Special Authorization";
			}
		});

		/**
		 * An STI service can be unselected if the status is still pending an an enrollment error exists.
		 */
		const stiCanBeUnregistered = computed(() => {
			return (
				apiServicesResponse.value?.stiEnrollmentStatus === StiEnrollmentState.Pending &&
				isStiEnrollmentValid.value === false
			);
		});

		return {
			studyId,
			participantId,
			registeredForQuickMedicationSupport,
			registeredForCompleteMedicationSupport,
			registeredForDiscountServiceSelection,
			registeredForReimbursementServiceSelection,
			isLegacy,
			uciCode,
			resendCode,

			stiEnrollmentStatus,
			StiEnrollmentState,
			StiEnrollmentCategoryStatus,

			discountEnrollmentStatus,
			reimbursementEnrollmentStatus,
			reimbursementEnrollmentClass,
			discountEnrollmentClass,
			stiEnrollmentClass,

			isStiEnrollmentValid,
			stiEnrollmentErrorMessage,
			isDiscountBenefitClaimed,
			reimbursementJourneyStatus,
			reimbursementJourneyDescription,
			reimbursementJourneyStatusText,
			stiCanBeUnregistered,
		};
	},
});
