import { NewInteractionQuestionItem, QuestionModel } from "@/api/LumediApi";

export function findItemBySubCode(
	question: QuestionModel,
	subCode: string,
): NewInteractionQuestionItem | undefined {
	for (const group of question.groups) {
		for (const subGroup of group.subGroups) {
			for (const item of subGroup.items) {
				if (item.subCode === subCode) return item;
			}
		}
	}

	return undefined;
}

export function findAnswerValueOfItem(question: QuestionModel, subCode: string): string | undefined {
	for (const group of question.groups) {
		for (const subGroup of group.subGroups) {
			for (const item of subGroup.items) {
				if (item.subCode === subCode) return item.value;
			}
		}
	}

	return undefined;
}

export function getItemCode(questionItem: NewInteractionQuestionItem): string {
	let code = questionItem.uniqueCode;

	if (questionItem.subCode != undefined) code = `${code}.${questionItem.subCode}`;

	return code!;
}

export function findCodeForItem(question: QuestionModel, subCode: string): string {
	const item = findItemBySubCode(question, subCode);

	if (item === undefined) throw new Error(`Unable to find question item for code ${subCode}`);

	let code = item.uniqueCode;

	if (subCode != undefined) code = `${code}.${subCode}`;

	return code!;
}
