
import AeLayout from "@/components/ae-engine/layouts/AeLayout.vue";
import utils from "@/utils/utils";
import Calendar from "primevue/calendar";
import RadioButton from "primevue/radiobutton";
import { defineComponent, onMounted, ref } from "vue";
import QuestionRenderer from "../ae-engine/QuestionRenderer.vue";
import DateQuestion from "../ae-engine/question-types/DateQuestion.vue";
import MultiLineTextQuestion from "../ae-engine/question-types/MultiLineTextQuestion.vue";
import NumberQuestion from "../ae-engine/question-types/NumberQuestion.vue";
import SingleChoiceQuestion from "../ae-engine/question-types/SingleChoiceQuestion.vue";
import TextQuestion from "../ae-engine/question-types/TextQuestion.vue";

import { AeInteractionClient, AeInteractionModel } from "@/api/LumediApi";
import InteractionRenderer from "../ae-engine/InteractionRenderer.vue";
export default defineComponent({
	props: {},
	components: {
		RadioButton,
		Calendar,
		SingleChoiceQuestion,
		DateQuestion,
		TextQuestion,
		NumberQuestion,
		MultiLineTextQuestion,
		QuestionRenderer,
		InteractionRenderer,
		AeLayout,
	},

	setup(props, context) {
		const interaction = ref(undefined as AeInteractionModel | undefined);
		const aeForm = ref({
			consentProvided: undefined as boolean | undefined,
			initialRecievedDate: undefined as Date | undefined,
			testText: undefined as string | undefined,
			testNumber: undefined as number | undefined,
		});

		const studyId = utils.sessionStudyId();

		// Fetch the data for participant in context
		const queryParams = new URLSearchParams(window.location.search);
		const participantId = ref(queryParams.get("p"));

		const consentOptions = ref([
			{
				label: "Yes",
				value: true,
			},
			{
				label: "No",
				value: false,
			},
		]);

		const fetchInteraction = async () => {
			// TODO: Form labels should come from the interaction data.
			const aeClient = new AeInteractionClient();
			if (participantId.value) {
				interaction.value = await aeClient.getAeInteraction(studyId, participantId.value);
			}
		};

		onMounted(async () => {
			await fetchInteraction();
		});

		const saveInteraction = async () => {};

		return {
			interaction,
			aeForm,
			consentOptions,
			fetchInteraction,
			saveInteraction,
			participantId,
		};
	},
});
