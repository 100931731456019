import { defineStore } from "pinia";

export enum PatientInteractionAction {
	/**
	 * The patient interaction is being edited.
	 */
	Edit,

	/**
	 * The patient interaction is being created.
	 */
	Create,
}

export const usePatientInteractionStore = defineStore({
	id: "patient-interactions",
	state: () => ({
		interactionId: null as number | null,
		patientId: null as string | null,
		patientInteractionId: null as number | null,
		isHubConnected: false,

		/**
		 * The number of concurrent users that have this interaction open.
		 */
		concurrentUserCount: 0,
		patientInteractionAction: PatientInteractionAction.Create,
	}),
});
