
import { ConsultationType, ProgramPatientChartClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Card from "primevue/card/Card";
import { computed, defineComponent, onMounted, ref } from "vue";
import ServicesBannerConsultationType from "./ServicesBannerConsultationType.vue";

export default defineComponent({
	name: "TresibaServicesBanner",
	components: { Card, ServicesBannerConsultationType },
	setup() {
		const consultationType = ref<ConsultationType | undefined>();

		const isOneTimeConsult = computed(() => consultationType.value === ConsultationType.OneTimeConsult);

		const isOngoingSupport = computed(() => consultationType.value === ConsultationType.OngoingSupport);

		onMounted(async () => {
			const studyId = utils.sessionStudyId();
			const queryParams = new URLSearchParams(window.location.search);
			const patientId = queryParams.get("id");
			if (!patientId) return;

			const client = new ProgramPatientChartClient();
			consultationType.value = await client.getPatientConsultationType(studyId, patientId);
		});

		return {
			isOneTimeConsult,
			isOngoingSupport,
		};
	},
});
