
import { QuestionModel } from "@/api/LumediApi";
import QuestionRenderer from "@/components/ae-engine/QuestionRenderer.vue";
import { findCodeForItem } from "@/components/ae-engine/QuestionUtils";
import { computed, defineComponent } from "vue";
export default defineComponent({
	props: {
		form: {
			type: Object as () => { [k: string]: { [k: string]: any | undefined } },
			required: true,
		},
		questionsByUniqueCode: {
			type: Object as () => { [k: string]: QuestionModel },
			required: true,
		},
		number: Number,
	},
	setup(props, context) {
		const updateAnswer = (questionId: number, value: { [k: string]: any | undefined }) => {
			const event = {
				questionId: questionId,
				answer: value,
			};

			context.emit("input", event);
		};

		const getItem = (uniqueCode: string, subCode: string) => {
			for (const group of props.questionsByUniqueCode[uniqueCode].groups) {
				for (const subGroup of group.subGroups) {
					for (const item of subGroup.items) {
						if (item.subCode === subCode) return item;
					}
				}
			}

			return undefined;
		};

		const updateLocalValue = (uniqueCode: string, subCode: string, value: any) => {
			const questionId = props.questionsByUniqueCode[uniqueCode].questionID;

			const currentValue = props.form[questionId];
			const updatedValue = { ...currentValue };

			const code = findCodeForItem(props.questionsByUniqueCode[uniqueCode], subCode);

			updatedValue[code] = value;

			const event = {
				questionId: questionId,
				answer: updatedValue,
			};
			context.emit("input", event);
		};

		const getValueOfQuestionItem = (uniqueCode: string, subCode: string) => {
			const question = props.questionsByUniqueCode[uniqueCode];
			const code = findCodeForItem(question, subCode);

			return props.form[question.questionID][code];
		};

		const stoppedDateDisabled = computed(() => {
			return getValueOfQuestionItem(`AE.MED.INFO.${props.number}`, "ONGOING") !== "Stopped";
		});

		return {
			updateAnswer,
			getItem,
			updateLocalValue,
			getValueOfQuestionItem,
			stoppedDateDisabled,
		};
	},
	components: {
		QuestionRenderer,
	},
});
