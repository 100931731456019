/**
 * Determines the last index in a given array or string where a specified condition is met.
 * @param {Array<any>|string} iterable - The array or string to be examined.
 * @param {function(any):boolean} predicate - A function that tests each element for a specific condition.
 * @returns {number} - Returns the last index where the element meets the condition, or -1 if no elements meet the condition.
 */
export function findLastIndex(
	iterable: Array<any> | string,
	predicate: (element: any) => boolean,
): number {
	// Start from the end of the array
	for (let i = iterable.length - 1; i >= 0; i--) {
		// If the current item satisfies the predicate, return its index
		if (predicate(iterable[i])) {
			return i;
		}
	}
	// If no item satisfies the predicate, return -1
	return -1;
}
