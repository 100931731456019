
import { EmaTimeWindowsClient, TimeWindowResult, UpdateTimeWindowModel } from "@/api/LumediApi";
import Vue, { PropType } from "vue";

const timeWindowsClient = new EmaTimeWindowsClient();

export default Vue.extend({
	props: {
		studyId: String,
		timeWindows: Array as PropType<TimeWindowResult[]>,
	},
	data() {
		return {
			displayNewDialog: false,
			newStart: "0:0:00",
			newEnd: "0:0:00",
		};
	},

	methods: {
		async deleteTimeWindow(timeWindow: TimeWindowResult) {
			await timeWindowsClient.deleteTimeWindow(timeWindow.id);

			const index = this.timeWindows.indexOf(timeWindow);

			this.timeWindows.splice(index, 1);

			this.$toast.add({
				severity: "success",
				summary: "Time Window has been deleted.",
				life: 3000,
			});
		},

		async createTimeWindow() {
			const model = {
				startTime: this.newStart,
				endTime: this.newEnd,
			} as UpdateTimeWindowModel;

			const createdTimeWindow = await timeWindowsClient.createTimeWindow(this.studyId, model);

			this.timeWindows.push(createdTimeWindow);

			this.displayNewDialog = false;
			this.newStart = "0:0:00";
			this.newEnd = "0:0:00";

			this.$toast.add({
				severity: "success",
				summary: "Time Window has been created.",
				life: 3000,
			});
		},
	},
});
