
import { StudySettingsClient, UpdateStudyBrandingModel } from "@/api/LumediApi";
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";

import utils from "@/utils/utils";
import ColorPicker from "primevue/colorpicker";
export default defineComponent({
	name: "update-study-branding",
	components: {
		ColorPicker,
	},
	setup(props, context) {
		const root = getCurrentInstance()!.proxy.$root;

		const studyId = ref(utils.sessionStudyId());
		const primaryColor = ref("");

		onMounted(async () => {
			const client = new StudySettingsClient();
			const branding = await client.getStudyBranding(studyId.value);
			primaryColor.value = branding.primaryColor ?? "";
		});

		const save = async () => {
			const client = new StudySettingsClient();
			await client.updateStudyPrimaryColor(studyId.value, {
				primaryColor: primaryColor.value,
			} as UpdateStudyBrandingModel);

			root.$toast.add({ severity: "success", summary: "Saved", life: 3000 });
		};

		return {
			studyId,
			primaryColor,

			save,
		};
	},
});
