
import Chip from "primevue/chip/Chip";
import { defineComponent } from "vue";

export default defineComponent({
	name: "ServicesBannerIndicator",
	components: { Chip },
	props: {
		active: { type: Boolean, required: true, default: false },
		label: { type: String, required: true },
	},
	setup() {
		const resolveIcon = (value: boolean | undefined) => {
			return value ? "pi pi-check p-ml-2" : "pi pi-times p-ml-2";
		};

		const resolveClassName = (value: boolean | undefined) => {
			return value ? "custom-chip-registered" : "custom-chip-unregistered";
		};

		return {
			resolveIcon,
			resolveClassName,
		};
	},
});
