
import {
	ParticipantsSummaryResult,
	PatientStatus,
	PatientStatusResult,
	PatientSummaryStatusResult,
	StudyParticipantsClient,
} from "@/api/LumediApi";
import utils from "@/utils/utils";
import { defineComponent } from "vue";

export default defineComponent({
	name: "ParticipantsSummary",
	data() {
		return {
			summary: undefined as ParticipantsSummaryResult | undefined,
			patients: undefined as PatientSummaryStatusResult[] | undefined,
			filters: {
				global: { value: null },
			},
			showPatientsDialog: false,
			studyId: "",
		};
	},
	async mounted() {
		this.studyId = utils.sessionStudyId();

		//@ts-ignore
		const client = new StudyParticipantsClient();
		this.summary = await client.getSummary(this.studyId);
	},
	computed: {
		statusCounts(): PatientStatusResult[] {
			if (this.summary === undefined || this.summary.statusCounts === undefined) return [];

			return this.summary.statusCounts.filter((x) => x.patientStatus !== PatientStatus.TestData);
		},

		enrolledCount(): number {
			if (!this.patients) return 0;

			return this.patients.filter((x) => x.status == PatientStatus.Enrolled).length;
		},

		partialCount(): number {
			if (!this.patients) return 0;

			return this.patients.filter((x) => x.status == PatientStatus.Recruiting).length;
		},

		inactiveCount(): number {
			if (!this.patients) return 0;

			return this.patients.filter((x) => x.status == PatientStatus.Inactive).length;
		},

		aeCount(): number {
			if (!this.patients) return 0;

			return this.patients.filter((x) => x.status == PatientStatus.AeOnly).length;
		},

		permanentlyIncompleteCount(): number {
			if (!this.patients) return 0;

			return this.patients.filter((x) => x.status == PatientStatus.PermanentlyIncomplete).length;
		},

		doesAnyPatientHaveEnrolledSubStatus(): boolean {
			if (!this.patients) return false;
			console.log(this.patients.map((x) => x.enrolledSubStatus));

			return this.patients.some((x) => x.enrolledSubStatus !== null);
		},
	},

	methods: {
		async fetchPatients() {
			const patientApi = new StudyParticipantsClient();
			this.patients = await patientApi.getParticipantsForStatus(this.studyId);
		},

		async openDialog() {
			this.showPatientsDialog = true;

			await this.fetchPatients();
		},

		getStatusNameByValue(patientStatus: number): string {
			const statuses = {
				0: "Partial",
				1: "Enrolled",
				2: "Inactive",
				3: "Did Not Agree to be Contacted",
				4: "Ineligible",
				5: "Not Interested",
				6: "Permanently Incomplete",
				7: "Test Data",
				8: "AE Only",
			};

			//@ts-ignore
			if (statuses[patientStatus]) {
				//@ts-ignore
				return statuses[patientStatus];
			}

			return "";
		},
	},
});
