
import { QuestionModel } from "@/api/LumediApi";
import { findCodeForItem } from "@/components/ae-engine/QuestionUtils";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: Object as () => { [k: string]: any | undefined },
			required: true,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		model: {
			type: QuestionModel,
			required: true,
		},
	},
	components: {},
	methods: {
		updateItemValue(subCode: string, value: any) {
			const code = findCodeForItem(this.model, subCode);

			const updatedValue = { ...this.value };
			updatedValue[code] = value;

			this.$emit("input", updatedValue);
		},
	},
	/**
	 * To keep consistent with one-way binding. We do not directly update the answer value in the prop.
	 * Instead, whenever we need to update the value we can emit an input event containing the value upwards.
	 * The parent component is responsible for listening for that event and updating it as needed.
	 *
	 * We create a computed property for every question that makes up our complex question.
	 * Each computed property contains a get and a set.
	 *
	 * Get
	 * This will read from the value property (which is our dictionary of answers, where the key is the code).
	 * There is a helper method to get the correct full code of a question item, this becomes the key to the dictionary.
	 *
	 * Set
	 * This will call a helper method that builds the updated dictionary of answers and emits it upstream.
	 */
	computed: {
		// Create a computed property for every question inside the question

		localDiagnosis: {
			get(): string | undefined {
				const code = findCodeForItem(this.model, "DIAGNOSIS");
				return this.value[code];
			},
			set(value: string) {
				this.updateItemValue("DIAGNOSIS", value);
			},
		},

		localStartDate: {
			get(): string | undefined {
				const code = findCodeForItem(this.model, "STARTDATE");
				return this.value[code];
			},
			set(value: string) {
				this.updateItemValue("STARTDATE", value);
			},
		},

		/**
		 * Bind to the city question that is part of the mailing address question.
		 */
		localOngoing: {
			get(): string | undefined {
				const code = findCodeForItem(this.model, "ONGOING");
				return this.value[code];
			},
			set(value: string) {
				this.updateItemValue("ONGOING", value);
			},
		},
		localStopDate: {
			get(): string | undefined {
				const code = findCodeForItem(this.model, "STOPDATE");
				return this.value[code];
			},
			set(value: string) {
				this.updateItemValue("STOPDATE", value);
			},
		},
	},
});
