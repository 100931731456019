import { render, staticRenderFns } from "./PatientsWithoutPhysicianReport.vue?vue&type=template&id=ba026c64&scoped=true"
import script from "./PatientsWithoutPhysicianReport.vue?vue&type=script&lang=ts"
export * from "./PatientsWithoutPhysicianReport.vue?vue&type=script&lang=ts"
import style0 from "./PatientsWithoutPhysicianReport.vue?vue&type=style&index=0&id=ba026c64&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba026c64",
  null
  
)

export default component.exports