import axios from "axios";

export async function downloadFile(url: string, fallbackFileName?: string): Promise<void> {
	try {
		const response = await fetchFile(url);
		const contentDisposition = response.headers["content-disposition"];
		const fileName = extractFileName(contentDisposition, fallbackFileName);
		const blob = createBlob(response.data);
		triggerDownload(blob, fileName);
	} catch (error) {
		console.error(error);
		throw error;
	}
}

async function fetchFile(url: string) {
	return await axios({
		url: url,
		method: "GET",
		responseType: "blob",
	});
}

function extractFileName(contentDisposition: string | undefined, fallbackFileName?: string): string {
	if (!contentDisposition) return fallbackFileName ?? "downloaded-file";

	// Prefer RFC 5987 version of file name.
	const filenameStarRegex = /filename\*=UTF-8''([\w%.-]+)/i;
	const starMatches = filenameStarRegex.exec(contentDisposition);
	if (starMatches?.[1]) {
		return decodeURIComponent(starMatches[1]);
	}

	const filenameRegex = /filename="?(.+)"?/i;
	const matches = filenameRegex.exec(contentDisposition);
	if (matches?.[1]) {
		return matches[1].replace(/['"]/g, "");
	}

	return fallbackFileName ?? "downloaded-file";
}

function createBlob(data: any): Blob {
	return new Blob([data], { type: data.type });
}

function triggerDownload(blob: Blob, fileName: string): void {
	const fileURL = window.URL.createObjectURL(blob);
	const fileLink = document.createElement("a");
	fileLink.href = fileURL;
	fileLink.setAttribute("download", fileName);
	document.body.appendChild(fileLink);
	fileLink.click();
	document.body.removeChild(fileLink);
	window.URL.revokeObjectURL(fileURL);
}
