
import { AsteriskClient, AsteriskSummaryModel } from "@/api/LumediApi";
import utils from "@/utils/utils";
import Skeleton from "primevue/skeleton";
import { defineComponent } from "vue";

const callMetricsClient = new AsteriskClient();
export default defineComponent({
	components: {
		Skeleton,
	},
	data() {
		return {
			summary: undefined as AsteriskSummaryModel | undefined,
			studyId: "",
		};
	},

	computed: {
		isLoaded(): boolean {
			return this.summary !== undefined;
		},
	},

	async mounted() {
		this.studyId = utils.sessionStudyId();
		this.summary = await callMetricsClient.getSummary(this.studyId);
	},
});
