
import { defineComponent } from "vue";

import { EmaStudyClient, EmaStudyResult, InteractionResult, UpdateEmaStudyModel } from "@/api/LumediApi";
import ReminderIntervalsConfiguration from "@/components/ema/ReminderIntervalsConfiguration.vue";
import TimeWindowsConfiguration from "@/components/ema/TimeWindowsConfiguration.vue";
// TODO: Disabled while loading for buttons

// TODO: Determine the proper way to instantiate the client.
const apiClient = new EmaStudyClient();
export default defineComponent({
	data() {
		return {
			study: undefined as EmaStudyResult | undefined,
			isLoaded: false,
			interactions: [] as InteractionResult[],
			selectedInteractionId: undefined as number | undefined,
		};
	},
	async mounted() {
		try {
			await this.fetchStudy();
			await this.fetchInteractions();
		} catch (error) {
			// Currently the API returns NotFound if the study has not been configured as EMA.
		}

		this.isLoaded = true;
	},

	computed: {
		/**
		 * Determine if a study is configured for EMA.
		 */
		isEma(): boolean {
			if (!this.isLoaded) return false;

			if (this.study === undefined) return false;

			return this.study.isActive;
		},

		selectedInteractionName(): string | undefined {
			if (this.selectedInteractionId === undefined) return "";

			const interaction = this.interactions.find((x) => x.id === this.selectedInteractionId);
			return interaction?.name;
		},
	},

	methods: {
		async saveSettings() {
			const model = {
				numberOfDays: this.study?.numberOfDays,
				interactionId: this.selectedInteractionId,
				expirationTime: this.study?.expirationTime,
			} as UpdateEmaStudyModel;

			const study = await apiClient.updateEma(model);

			this.updateStudy(study);

			this.$toast.add({ severity: "success", summary: "Saved", life: 3000 });
		},

		async fetchStudy() {
			// TODO: This will grab the study in context, instead of passing in an ID. Determine if that's the pattern to continue with using vue.

			const study = await apiClient.getEmaStudy();
			this.updateStudy(study);
		},

		updateStudy(studyResult: EmaStudyResult) {
			this.study = studyResult;
			this.selectedInteractionId = studyResult.interaction?.id;
		},

		async fetchInteractions() {
			this.interactions = await apiClient.getInteractions();
		},

		async enableEma() {
			const study = await apiClient.enableEma();

			this.updateStudy(study);
		},

		async disableEma() {
			const study = await apiClient.disableEma();
			this.updateStudy(study);
		},

		toggleInteractionPanel(event: any) {
			// @ts-ignore
			this.$refs.interactionPanel.toggle(event);
		},

		async onInteractionSelect(event: any) {
			// @ts-ignore
			this.$refs.interactionPanel.hide();

			if (!this.study) return;

			this.selectedInteractionId = event.data.id;
		},
	},

	components: {
		TimeWindowsConfiguration,
		ReminderIntervalsConfiguration,
	},
});
