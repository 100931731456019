
import { PatientWeightJourneyClient, PatientWeightOverviewResult } from "@/api/LumediApi";
import utils from "@/utils/utils";
import ProgressBar from "primevue/progressbar";
import { defineComponent, ref } from "vue";
import PatientWeightChart from "./PatientWeightChart.vue";
import PatientWeightDetails from "./PatientWeightDetails.vue";
import PatientWeightTable from "./PatientWeightTable.vue";

export default defineComponent({
	name: "PatientWeightOverview",
	components: { PatientWeightChart, PatientWeightTable, ProgressBar, PatientWeightDetails },
	setup(props) {
		const isLoading = ref(false);

		const weightOverview = ref<PatientWeightOverviewResult>();

		fetchData();
		async function fetchData() {
			if (isLoading.value) return;

			try {
				isLoading.value = true;

				const queryParams = new URLSearchParams(window.location.search);
				const participantId = queryParams.get("id");

				if (!participantId) return;

				const studyId = utils.sessionStudyId();

				const client = new PatientWeightJourneyClient();
				weightOverview.value = await client.getPatientWeightOverview(studyId, participantId);
			} catch (error) {
				//
			}

			isLoading.value = false;
		}

		return {
			isLoading,
			weightOverview,
		};
	},
});
