
// TODO: Label for id in template

import InputNumber from "primevue/inputnumber";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: Number,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		InputNumber,
	},
	computed: {
		localValue: {
			get(): number | undefined {
				return this.value;
			},
			set(value: number | undefined) {
				this.$emit("input", value);
			},
		},

		metricWeight(): number | undefined {
			return this.localValue;
		},

		imperialWeight: {
			get(): number | undefined {
				if (this.metricWeight === undefined || (this.metricWeight as any) == "") return undefined;

				const lbs = Math.round((this.metricWeight / 0.453592) * 10) / 10;

				return lbs;
			},
			set(value: number | undefined) {
				if (value === undefined) {
					this.localValue = undefined;
					return;
				}

				if (value < 0) value = 0;

				const kgs = Math.round(value * 0.453592 * 10) / 10;

				this.localValue = kgs;
			},
		},
	},
});
