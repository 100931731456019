
import { PatientWeightInteractionResult } from "@/api/LumediApi";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	name: "PatientWeightTable",
	components: {},
	props: {
		model: {
			type: Array as PropType<PatientWeightInteractionResult[]>,
			default: () => [],
		},
	},
	setup(props) {
		const localModel = computed(() => {
			let lastWeight: number;
			return props.model.map((x) => {
				let weight: number | undefined = undefined;
				let colorClass = "neutral";
				let icon = "pi pi-minus";

				// Data point has a weight
				if (x.weight) {
					weight = parseFloat(x.weight.toFixed(1));

					colorClass = getColorClass(weight, lastWeight);
					icon = getIcon(weight, lastWeight);
					lastWeight = weight;
				}

				return {
					...x,
					weight: weight,
					colorClass: colorClass,
					icon: icon,
				};
			});
		});

		function getColorClass(value: number, prevValue: number) {
			if (value < prevValue) {
				return "loss";
			} else if (value > prevValue) {
				return "gain";
			} else return "neutral";
		}

		function getIcon(value: number, prevValue: number) {
			if (value < prevValue) {
				return "pi pi-arrow-down-right";
			} else if (value > prevValue) {
				return "pi pi-arrow-up-right";
			} else return "pi pi-minus";
		}

		return {
			localModel,
		};
	},
});
