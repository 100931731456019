
import { defineComponent } from "vue";
import Vue, { PropType } from "vue";
export default defineComponent({
	props: {
		value: {
			type: String,
			required: false,
		},
	},
	components: {},
});
