
// Vue
import { type IPatientWithInteractionSummaryResult, ReportsClient } from "@/api/LumediApi";
import utils from "@/utils/utils";
import { defineComponent } from "vue";
import PatientInteractionTable from "./PatientInteractionTable.vue";
// todo
// props, a tags

export default defineComponent({
	name: "adverse-events-statistics",

	components: { PatientInteractionTable },

	data: () => ({
		showFiledModal: false,
		showFaxedModal: false,
		filedCount: 0,
		faxedCount: 0,
		filed: [] as IPatientWithInteractionSummaryResult[],
		filedLoading: false,
		faxed: [] as IPatientWithInteractionSummaryResult[],
		faxedLoading: false,
		studyId: "",
	}),

	async mounted() {
		this.studyId = utils.sessionStudyId();

		if (this.studyId == null) {
			console.log("No study id provided");
			return;
		}
		const reportClient = new ReportsClient();
		const summary = await reportClient.getAeSummary(this.studyId);

		this.filedCount = summary.filedCount;
		this.faxedCount = summary.faxedCount;
	},

	methods: {
		async displayFiledDialog() {
			this.showFiledModal = true;

			if (this.studyId == null) {
				console.log("No study id provided");
				return;
			}

			const reportsClient = new ReportsClient();
			try {
				this.filedLoading = true;
				this.filed = await reportsClient.getPatientsWithAe(this.studyId);
			} finally {
				this.filedLoading = false;
			}
		},
		async displayFaxedDialog() {
			this.showFaxedModal = true;

			if (this.studyId == null) {
				console.log("No study id provided");
				return;
			}

			const reportsClient = new ReportsClient();
			try {
				this.faxedLoading = true;
				this.faxed = await reportsClient.getPatientsWithoutFaxedAe(this.studyId);
			} finally {
				this.faxedLoading = false;
			}
		},
	},
});
