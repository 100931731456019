
import { PatientStudyJourneyResult, StudyJourneyClient } from "@/api/LumediApi";
import { findLastIndex } from "@/utils/arrayUtils";
import utils from "@/utils/utils";
import dayjs from "dayjs";
import Chip from "primevue/chip";
import DataTable from "primevue/datatable/DataTable";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
export default defineComponent({
	name: "patient-program-journey",
	components: { Chip, DataTable },
	setup() {
		const studyId = ref(utils.sessionStudyId());
		const participantId = ref<string | null>();

		const internalSteps = ref([] as PatientStudyJourneyResult[]);

		onMounted(async () => {
			const client = new StudyJourneyClient();

			const queryParams = new URLSearchParams(window.location.search);

			participantId.value = queryParams.get("id");

			if (!participantId.value) return;

			internalSteps.value = await client.getPatientJourney(studyId.value, participantId.value);
		});

		const dateFormat = "DD MMM YYYY h:mm A";
		const steps = computed(() => {
			return internalSteps.value
				.sort((a, b) => a.order - b.order)
				.map((x) => {
					const appointments = x.appointments
						.filter((app) => app.completedOn == undefined)
						.map((app) => {
							let status = "";
							let statusClass = "";
							let icon = "";
							let dateValue = dayjs();
							let date = "";

							if (app.completedOn) {
								dateValue = dayjs(app.scheduledFor).tz("America/Toronto");
								date = dateValue.format(dateFormat);

								status = `${date} ET`;
								statusClass = "completed";
								icon = "pi pi-check";
							} else if (app.archivedOn) {
								dateValue = dayjs(app.scheduledFor).tz("America/Toronto");

								date = dateValue.format(dateFormat);

								status = `${date} ET`;
								statusClass = "archived";
								icon = "pi pi-times";
							} else {
								dateValue = dayjs(app.scheduledFor).tz("America/Toronto");
								date = dateValue.format(dateFormat);

								status = `${date} ET`;
								statusClass = "pending";
								icon = "pi pi-hourglass";
							}

							return {
								dateValue,
								status,
								statusClass,
								icon,
							};
						});

					const interactions = x.completedInteractions.map((pi) => {
						const dateValue = dayjs(pi.completedOn).tz("America/Toronto");
						const date = dateValue.format(dateFormat);

						const status = `${date} ET`;
						const statusClass = "completed";
						const icon = "pi pi-check";

						return {
							dateValue,
							status,
							statusClass,
							icon,
						};
					});

					const statuses = appointments
						.concat(interactions)
						.sort((a, b) => b.dateValue.toDate().getTime() - a.dateValue.toDate().getTime());

					return {
						...x,
						statuses,
					};
				});
		});

		const stepHeaders = ref<HTMLElement[]>();

		watch(stepHeaders, (newVal) => {
			if (!newVal) return;

			// Center last completed journey step
			type Step = (typeof steps.value)[0];
			const currentStep = findLastIndex(steps.value, (x: Step) => x.completedInteractions.length > 0);
			if (currentStep === -1) return;

			const stepElement = newVal[currentStep];
			stepElement.scrollIntoView({ inline: "center" });
		});

		return {
			studyId,
			participantId,

			steps,
			stepHeaders,
		};
	},
});
