
import { CreateEmailSenderModel, EmailSenderClient, StudyEmailSender } from "@/api/LumediApi";
import { defineComponent, getCurrentInstance, onMounted, ref } from "vue";

import utils from "@/utils/utils";

class Errors extends Map<string, string> {}

export default defineComponent({
	setup: () => {
		const client = new EmailSenderClient();
		const v = getCurrentInstance()!.proxy.$root;
		const errors = ref(new Errors());
		const studyId = ref(utils.sessionStudyId());
		const emailSender = ref(new StudyEmailSender());
		const isNew = ref(false);
		const isSaving = ref(false);

		const serverError = () =>
			v.$toast.add({
				severity: "error",
				summary: "Error communicating with the server",
				life: 3000,
			});

		const save = async () => {
			if (!isValid(emailSender.value)) return;

			try {
				isSaving.value = true;

				//create createmodel
				const model = emailSender.value as any;
				const createModel = model as CreateEmailSenderModel;
				createModel.isDefault = true; //for now, a study has 1 email sender and it's always set to default

				if (isNew.value === true) {
					await client.create(studyId.value, createModel);
				} else {
					await client.update(studyId.value, emailSender.value.emailSenderId, createModel);
				}

				v.$toast.add({ severity: "success", summary: "Saved", life: 3000 });
			} catch (ex: any) {
				serverError();
			} finally {
				isSaving.value = false;
			}
		};

		const isValid = (item: StudyEmailSender): boolean => {
			errors.value = new Errors();

			if (!item.port || item.port <= 0) errors.value.set("port", "Port number is invalid!");
			if (!item.fromEmail) errors.value.set("fromEmail", "From Email is empty!");
			if (!item.fromName) errors.value.set("fromName", "From Name is empty!");
			if (!item.emailServer) errors.value.set("emailServer", "Email Server is empty!");
			if (item.useAuthentication && !item.password)
				errors.value.set("password", "Password is required for authentication!");

			return errors.value.size == 0;
		};

		onMounted(async () => {
			const data = await client.get(studyId.value);

			if (data == null) {
				isNew.value = true;
				emailSender.value = new StudyEmailSender();
			} else {
				isNew.value = false;
				emailSender.value = data;
			}
		});

		return {
			errors,
			emailSender,
			isSaving,
			save,
		};
	},
});
