
import { PatientWeightInteractionResult } from "@/api/LumediApi";
import Chart from "primevue/chart";
import { computed, defineComponent, PropType } from "vue";

export default defineComponent({
	name: "PatientWeightChart",
	components: { Chart },
	props: {
		model: {
			type: Array as PropType<PatientWeightInteractionResult[]>,
			default: () => [],
		},
	},
	setup(props) {
		const localModel = computed(() => {
			return props.model.map((x) => {
				let weight: string | undefined = undefined;

				// Data point has a weight, round to 1 decimal
				if (x.weight) {
					weight = x.weight.toFixed(1);
				}

				return {
					...x,
					weight,
				};
			});
		});

		const chartData = computed(() => {
			const data = {
				labels: localModel.value?.map((x) => x.name),
				datasets: [
					{
						label: "Weight",
						data: localModel.value?.map((x) => x.weight),
						fill: false,
						borderColor: "#f69321",
						cubicInterpolationMode: "monotone",
						tension: 0.4,
					},
				],
			};

			return data;
		});

		const chartOptions = {
			elements: {
				point: {
					borderWidth: 8,
					hoverBorderWidth: 10,
				},
			},
			scales: {
				y: {
					ticks: {
						callback: function (value: number) {
							return Number(value.toFixed(2)) + " kg";
						},
					},
				},
			},
			plugins: {
				tooltip: {
					boxPadding: 6,
					callbacks: {
						label: function (context: any) {
							let label = context.dataset.label || "";
							if (label) {
								label += ": ";
							}
							if (context.parsed.y !== null) {
								label += context.parsed.y + " kg";
							}
							return label;
						},
					},
				},
			},
		};

		return {
			localModel,
			chartData,
			chartOptions,
		};
	},
});
