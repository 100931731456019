
import { QuestionModel } from "@/api/LumediApi";
import Vue, { defineComponent, ref } from "vue";
import AeLayout from "./layouts/AeLayout.vue";
import GridLayout from "./layouts/GridLayout.vue";
import LinearLayout from "./layouts/LinearLayout.vue";

/**
 * Renders the contents of an interaction.
 *
 */
export default defineComponent({
	props: {
		/**
		 * The questions in the interaction to render.
		 * TODO: This will likely change to a wrapping interaction model.
		 */
		questions: {
			type: Array as () => QuestionModel[],
			required: true,
		},

		/**
		 * The layout on how the questions are rendered.
		 * If not specified, renders the questions linearly as a list.
		 * Layouts can be for specific interactions, or generic that can be applied to any interaction.
		 */
		layout: {
			type: Object,
			default: () => AeLayout,
		},

		languageCode: {
			type: String,
			default: "en",
		},
	},
	components: {
		LinearLayout,
		GridLayout,
	},

	setup(props, context) {
		const formData = {} as { [k: string]: { [k: string]: any | undefined } };

		// Loop through all questions and their nested item structure.
		// Builds up a form object that holds the answer value to each question in the interaction.
		for (const question of props.questions) {
			const answers: { [k: string]: any | undefined } = {};
			for (const group of question.groups) {
				for (const subGroup of group.subGroups) {
					for (const item of subGroup.items) {
						let code = item.uniqueCode;
						if (item.subCode != undefined) {
							code = `${code}.${item.subCode}`;
						}
						answers[code!] = undefined;
					}
				}
			}
			formData[question.questionID] = answers;
		}

		const form = ref(formData);

		/**
		 * The value of a question's answer has been modified.
		 * Updates the form object with the new answer value.
		 */
		const updateAnswer = (event: { questionId: number; answer: { [k: string]: any | undefined } }) => {
			Vue.set(form.value, event.questionId, { ...event.answer });
		};

		return {
			form,
			updateAnswer,
		};
	},
});
