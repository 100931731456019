
import Calendar from "primevue/calendar";
import { defineComponent } from "vue";
export default defineComponent({
	props: {
		value: {
			type: Date,
			required: false,
		},
		label: {
			type: String,
			default: "Date",
		},
		displayLabels: {
			type: Boolean,
			default: true,
		},
		displayInstructions: {
			type: Boolean,
			default: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Calendar,
	},
	computed: {
		localValue: {
			get(): Date | undefined {
				return this.value;
			},
			set(value: Date | undefined) {
				this.$emit("input", value);
			},
		},
	},
});
