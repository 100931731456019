
// Vue
import { defineComponent } from "vue";

export default defineComponent({
	name: "ConfirmationDialog",

	props: {
		showDialog: Boolean,
		rejectLabel: {
			type: String,
			default: "Cancel",
		},
		acceptLabel: {
			type: String,
			default: "Save",
		},
		header: {
			type: String,
			default: "Confirm",
		},
		submitting: Boolean,
		submitFailed: Boolean,
	},

	emits: ["submit", "close"],
});
